import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { CacheProvider } from './lib/cache/context';

import './index.css';
import './styles/text.css';
import './styles/links.css';
import './styles/buttons.css';
import './styles/inputs.css';
import './styles/slider.css';
import './styles/icons.css';
import './styles/scrollbar.css';
import './styles/select.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://6d34287917c8e3ac6255a806b3cc013a@o309988.ingest.us.sentry.io/4506877116022784',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api\.voximity\.chat/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <CacheProvider>
      <App />
    </CacheProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
