import { useRef, useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import { enableSelfVideo, disableSelfVideo } from '../../../lib/canvas/videos';
import Participant from './Participant';

function Videos() {
  const selfVideoRef = useRef(null);
  const self = useDyteSelector((m) => m.self);
  const participants = useDyteSelector((m) => m.participants.joined);

  useEffect(() => {
    if (!self.videoEnabled || !self.videoTrack) {
      disableSelfVideo();
      selfVideoRef.current.srcObject = null;
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(self.videoTrack);
    selfVideoRef.current.srcObject = stream;

    const enable = () => {
      enableSelfVideo(selfVideoRef, selfVideoRef.current.videoHeight);
    };

    const videoRefCopy = selfVideoRef.current;
    videoRefCopy.addEventListener('loadedmetadata', enable, { once: true });
    videoRefCopy.addEventListener('resize', enable);
    return () => {
      disableSelfVideo();
      if (videoRefCopy) {
        videoRefCopy.removeEventListener('resize', enable);
      }
    };
  }, [self.videoEnabled, self.videoTrack]);

  return (
    <>
      <video style={{ display: 'none' }} ref={selfVideoRef} autoPlay playsInline muted />

      {participants.toArray().map((p) => (
        <Participant key={p.id} participant={p} />
      ))}
    </>
  );
}

export default Videos;
