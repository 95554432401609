import { useState, useRef, useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import * as sentry from '../../lib/sentry';
import './Sounds.css';

function Sounds({ soundEnabled, setSoundEnabled, overallVolume }) {
  const [enterPlayed, setEnterPlayed] = useState(false);

  const enterRef = useRef(null);
  const leaveRef = useRef(null);

  useEffect(() => {
    if (enterRef.current) {
      enterRef.current.volume = overallVolume;
    }

    if (leaveRef.current) {
      leaveRef.current.volume = overallVolume;
    }

    if (!enterPlayed && enterRef.current && soundEnabled) {
      enterRef.current.play().catch(sentry.reportError);
      setEnterPlayed(true);
    }
  }, [enterRef, leaveRef, overallVolume, soundEnabled, enterPlayed]);

  const participants = useDyteSelector((m) => m.participants.joined);
  useEffect(() => {
    const playEnter = () => soundEnabled && enterRef.current.play().catch(sentry.reportError);
    const playLeave = () => soundEnabled && leaveRef.current.play().catch(sentry.reportError);
    participants.on('participantJoined', playEnter);
    participants.on('participantLeft', playLeave);

    return () => {
      participants.off('participantJoined', playEnter);
      participants.off('participantLeft', playLeave);
    };
  }, [participants, soundEnabled]);

  const onEnableSound = () => {
    enterRef.current.load();
    leaveRef.current.load();
    setSoundEnabled(true);
  };

  return (
    <>
      {!soundEnabled && (
        <div className="Sounds-button-container">
          <button className="btn-primary" onClick={onEnableSound}>
            🔈 Enable sound
          </button>
        </div>
      )}
      <audio ref={enterRef} src="/audio/effects/enter.mp3" />
      <audio ref={leaveRef} src="/audio/effects/leave.mp3" />
    </>
  );
}

export default Sounds;
