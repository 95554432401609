import { useForm } from 'react-hook-form';
import useCache from '../../../lib/cache/context';
import actions from '../../../lib/cache/actions';
import Modal from '../../../components/modal/Modal';
import './NewRoom.css';

function NewRoom({ setNewRoomOpen }) {
  const { dispatch } = useCache();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onCreate = async ({ name }) => {
    await actions.rooms.create(dispatch, { name });
    setNewRoomOpen(false);
    reset();
  };

  return (
    <Modal name="new-room" title="New Room" close={() => setNewRoomOpen(false)}>
      <form onSubmit={handleSubmit(onCreate)}>
        <div className="NewRoom-field">
          <label className="NewRoom-label">Room name</label>
          <input
            disabled={isSubmitting}
            autoFocus
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <div className="error">Name required</div>}
        </div>
        <input disabled={isSubmitting} className="btn-primary" type="submit" value="Create" />
      </form>
    </Modal>
  );
}

export default NewRoom;
