import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { GithubPicker } from 'react-color';
import Modal from '../../../components/modal/Modal';
import actions from '../../../lib/cache/actions';
import useCache from '../../../lib/cache/context';
import './NewGroup.css';

function NewGroup({ setIsOpen }) {
  const [isPicking, setIsPicking] = useState(false);
  const [color, setColor] = useState('#FCCB00');
  const { state, dispatch } = useCache();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onCreate = async ({ name }) => {
    const group = await actions.groups.create(dispatch, state.room.data.id, { name, color });
    await actions.users.joinGroup(dispatch, state.self.data.id, group.id);
    setIsOpen(false);
    reset();
  };

  const onColorPick = ({ hex }) => {
    setColor(hex);
    setIsPicking(false);
  };

  return (
    <Modal
      name="new-group"
      description="People in your group sound the same no matter how far away they are."
      title="New Group"
      close={() => setIsOpen(false)}
    >
      <form onSubmit={handleSubmit(onCreate)}>
        <div className="NewGroup-field">
          <label className="NewGroup-label">Group name</label>
          <input
            disabled={isSubmitting}
            autoFocus
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <div className="error">Name required</div>}
        </div>
        <div className="NewGroup-field">
          <label className="NewGroup-label">Group color</label>
          {!isPicking && (
            <div
              className="NewGroup-color"
              style={{ backgroundColor: color }}
              onClick={() => setIsPicking(!isPicking)}
            />
          )}
          {isPicking && (
            <div className="NewGroup-picker">
              <GithubPicker color={color} onChangeComplete={onColorPick} triangle="hide" />
            </div>
          )}
        </div>
        <input disabled={isSubmitting} className="btn-primary" type="submit" value="Create" />
      </form>
    </Modal>
  );
}

export default NewGroup;
