import { useState, useCallback, useEffect } from 'react';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import useCache from '../../lib/cache/context';
import * as sentry from '../../lib/sentry';
import * as dyte from '../../lib/dyte';
import actions from '../../lib/cache/actions';
import { initializeCanvas } from '../../lib/canvas/canvas';
import { initializeUser, updateMovement } from '../../lib/canvas/users';
import { updateParticipants } from '../../lib/canvas/participants';
import { updatePositions } from '../../lib/canvas/positions';
import { updateGroups } from '../../lib/canvas/groups';
import { updateWhispers } from '../../lib/canvas/whispers';
import { updateMessages } from '../../lib/canvas/messages';
import Audio from './audio/Audio';
import Screens from './screens/Screens';
import Videos from './videos/Videos';
import Popup from './Popup';
import Pins from './pins/Pins';
import Bar from './bar/Bar';
import Sounds from './Sounds';
import Status from './status/Status';
import ChatGrid from './chat/ChatGrid';
import WebglWarning from './WebglWarning';
import './Room.css';

const { uIOhook } = typeof window.require === 'function' && window.require('uiohook-napi');

// Need to detect Safari because audio files must be loaded
// by direct user interaction.
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function Room({ isElectron }) {
  const { state, dispatch } = useCache();

  const [canvasInitialized, setCanvasInitialized] = useState(false);

  const [soundEnabled, setSoundEnabled] = useState(!isSafari);
  const [overallVolume, setOverallVolume] = useState(1);
  const [whisperVolume, setWhisperVolume] = useState(0.1);
  const [proximityEnabled, setProximityEnabled] = useState(true);
  const [screenVolumes, setScreenVolumes] = useState({});
  const [userVolumes, setUserVolumes] = useState({});
  const [groupVolumes, setGroupVolumes] = useState({});
  const [distances, setDistances] = useState({});
  const [pins, setPins] = useState({});
  const [pinsHidden, setPinsHidden] = useState(false);
  const [popup, setPopup] = useState(null);
  const [barSwitch, setBarSwitch] = useState('chat');
  const [showWebglWarning, setShowWebglWarning] = useState(false);

  const { meeting } = useDyteMeeting();
  const participants = useDyteSelector((m) => m.participants.joined);
  const self = useDyteSelector((m) => m.self);

  const handleCanvasRef = useCallback(
    (node) => {
      if (node === null) {
        return;
      }

      if (canvasInitialized) {
        return;
      }

      try {
        initializeCanvas(node);
      } catch (err) {
        console.error(err);
        setShowWebglWarning(true);
        return;
      }

      setCanvasInitialized(true);
    },
    [canvasInitialized]
  );

  useEffect(() => {
    (async () => {
      try {
        await meeting.join();
      } catch (err) {
        sentry.reportError(err);
        actions.room.remove(dispatch);
      }
    })();

    const leaveTimer = setTimeout(() => {
      dyte.leaveMeeting(meeting);
    }, 3 * 60 * 60 * 1000);

    return () => {
      clearTimeout(leaveTimer);
    };

    // Only run once to avoid Dyte errors.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await actions.users.fetch(dispatch, state.room.data.id);
      await actions.groups.fetch(dispatch, state.room.data.id);
      await actions.messages.fetch(dispatch, state.room.data.id);
      await actions.keyBindings.fetch(dispatch);
    })();
  }, [state.room.data.id, dispatch]);

  useEffect(() => {
    if (!canvasInitialized) {
      return;
    }

    // Check for the avatar to ensure that the full user exists,
    // and not just a portion that was added elsewher. For example, just the position.
    const selfUser = state.users.data.find((u) => u.id === state.self.data.id);
    if (!selfUser || !selfUser.avatar) {
      return;
    }

    (async () => {
      try {
        await initializeUser(selfUser, dispatch);
      } catch (err) {
        sentry.reportError(err);
        dyte.leaveMeeting(meeting);
        actions.room.remove(dispatch);
      }
    })();
  }, [canvasInitialized, meeting, state.users.data, state.self.data.id, dispatch]);

  // Let Safari know that we need mic and audio.
  useEffect(() => {
    if (navigator.audioSession && navigator.audioSession.type) {
      navigator.audioSession.type = 'play-and-record';
    }
  }, []);

  useEffect(() => {
    if (!uIOhook) {
      return;
    }

    uIOhook.removeAllListeners('keydown');
    uIOhook.removeAllListeners('keyup');
    uIOhook.removeAllListeners('mousedown');
    uIOhook.removeAllListeners('mouseup');

    const pressed = new Set();
    const activeBinds = new Set();

    const onDown = (event) => {
      const key = event.keycode || `Mouse${event.button}`;
      if (pressed.has(key)) {
        return;
      }

      pressed.add(key.toString());

      for (const keyBinding of state.keyBindings.data) {
        if (!keyBinding.enabled) {
          continue;
        }

        if (activeBinds.has(keyBinding.name)) {
          continue;
        }

        const isActive = keyBinding.keys.split(',').every((key) => pressed.has(key));
        if (isActive) {
          activeBinds.add(keyBinding.name);
          actions.keyBindings.activate(dispatch, keyBinding.name);
        }
      }
    };

    const onUp = (event) => {
      const key = event.keycode || `Mouse${event.button}`;
      pressed.delete(key.toString());

      for (const keyBinding of state.keyBindings.data) {
        if (!activeBinds.has(keyBinding.name)) {
          continue;
        }

        const isActive = keyBinding.keys.split(',').every((key) => pressed.has(key));
        if (!isActive) {
          activeBinds.delete(keyBinding.name);
          actions.keyBindings.deactivate(dispatch, keyBinding.name);
        }
      }
    };

    uIOhook.on('keydown', onDown);
    uIOhook.on('keyup', onUp);
    uIOhook.on('mousedown', onDown);
    uIOhook.on('mouseup', onUp);
    uIOhook.start();
  }, [dispatch, state.keyBindings.data]);

  useEffect(() => {
    if (canvasInitialized) {
      updatePositions(state.self.data.id, state.users.data, setDistances);
    }
  }, [canvasInitialized, state.self.data.id, state.users.data]);

  useEffect(() => {
    if (canvasInitialized) {
      updateGroups(state.self.data.id, state.users.data, state.groups.data);
    }
  }, [canvasInitialized, state.self.data.id, state.users.data, state.groups.data]);

  useEffect(() => {
    if (canvasInitialized) {
      updateWhispers(state.self.data.id, state.users.data, state.groups.data);
    }
  }, [canvasInitialized, state.self.data.id, state.users.data, state.groups.data]);

  useEffect(() => {
    if (canvasInitialized) {
      updateMessages(state.self.data.id, state.messages.data);
    }
  }, [canvasInitialized, state.self.data.id, state.messages.data]);

  useEffect(() => {
    if (canvasInitialized) {
      updateMovement(state.self.data.id, state.keyBindings.active, dispatch);
    }
  }, [canvasInitialized, state.self.data.id, state.keyBindings.active, dispatch]);

  const participantIds = [...participants.values()].map((v) => v.customParticipantId).join();
  useEffect(() => {
    if (canvasInitialized) {
      (async () => {
        try {
          await updateParticipants(
            {
              selfId: state.self.data.id,
              users: state.users.data,
              groups: state.groups.data,
            },
            dispatch,
            participantIds,
            setPopup
          );
        } catch (err) {
          sentry.reportError(err);
          dyte.leaveMeeting(meeting);
          actions.room.remove(dispatch);
        }
      })();
    }

    // Because participants is a mutable map, it can't be relied on to
    // trigger this useEffect when it's changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasInitialized,
    participantIds,
    state.self.data.id,
    state.users.data,
    state.groups.data,
    dispatch,
  ]);

  return (
    self.roomJoined && (
      <div className="Room">
        <div className="Room-canvas-container" ref={handleCanvasRef} />

        {/* <Help /> */}
        <Status isElectron={isElectron} />

        <Bar
          isElectron={isElectron}
          setPins={setPins}
          pinsHidden={pinsHidden}
          setPinsHidden={setPinsHidden}
          barSwitch={barSwitch}
          setBarSwitch={setBarSwitch}
          overallVolume={overallVolume}
          setOverallVolume={setOverallVolume}
          whisperVolume={whisperVolume}
          setWhisperVolume={setWhisperVolume}
          proximityEnabled={proximityEnabled}
          setProximityEnabled={setProximityEnabled}
        />

        <Audio
          soundEnabled={soundEnabled}
          overallVolume={overallVolume}
          whisperVolume={whisperVolume}
          proximityEnabled={proximityEnabled}
          groupVolumes={groupVolumes}
          userVolumes={userVolumes}
          distances={distances}
        />

        <Screens
          soundEnabled={soundEnabled}
          overallVolume={overallVolume}
          whisperVolume={whisperVolume}
          proximityEnabled={proximityEnabled}
          groupVolumes={groupVolumes}
          screenVolumes={screenVolumes}
          userVolumes={userVolumes}
          distances={distances}
          pins={pins}
        />

        <Videos />

        <Sounds
          soundEnabled={soundEnabled}
          setSoundEnabled={setSoundEnabled}
          overallVolume={overallVolume}
        />

        {!!popup && (
          <Popup
            popup={popup}
            setPopup={setPopup}
            setUserVolumes={setUserVolumes}
            userVolumes={userVolumes}
            setScreenVolumes={setScreenVolumes}
            screenVolumes={screenVolumes}
            setGroupVolumes={setGroupVolumes}
            groupVolumes={groupVolumes}
            setPins={setPins}
            pins={pins}
          />
        )}

        <Pins
          pins={pins}
          setPins={setPins}
          pinsHidden={pinsHidden}
          soundEnabled={soundEnabled}
          overallVolume={overallVolume}
          screenVolumes={screenVolumes}
          setPopup={setPopup}
        />

        <ChatGrid barSwitch={barSwitch} />

        <WebglWarning isOpen={showWebglWarning} setIsOpen={setShowWebglWarning} />
      </div>
    )
  );
}

export default Room;
