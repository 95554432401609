import { useRef, useEffect, useState } from 'react';
import { animateIndicator } from '../../../lib/canvas/indicator';
import useCache from '../../../lib/cache/context';
import * as vol from '../../../lib/volume';

function Participant({
  participant,
  soundEnabled,
  overallVolume,
  whisperVolume,
  proximityEnabled,
  userVolumes,
  groupVolumes,
  distances,
}) {
  const { state } = useCache();
  const userId = participant.customParticipantId;

  const [gainNode, setGainNode] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    if (!participant.audioEnabled || !participant.audioTrack) {
      audioRef.current.srcObject = null;
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(participant.audioTrack);
    audioRef.current.srcObject = stream;

    const audioContext = new window.AudioContext();
    const source = audioContext.createMediaStreamSource(stream);
    const analyser = audioContext.createAnalyser();
    const gain = audioContext.createGain();
    setGainNode(gain);

    source.connect(analyser).connect(gain).connect(audioContext.destination);

    const removeAnimation = animateIndicator(analyser, userId);

    return () => {
      removeAnimation();
      source.disconnect();
    };
  }, [userId, participant.audioEnabled, participant.audioTrack]);

  useEffect(() => {
    const volume = vol.get(
      {
        self: state.self.data,
        users: state.users.data,
        groups: state.groups.data,
      },
      participant.customParticipantId,
      vol.proximity(distances, proximityEnabled),
      vol.user(userVolumes),
      vol.group(groupVolumes),
      vol.whisper(whisperVolume),
      vol.isolate(),
      vol.overall(overallVolume),
      vol.enabled(soundEnabled)
    );

    if (gainNode) {
      // Need to use a gain node to adjust volume because
      // Safari doesn't allow changing volume on mobile >_>
      gainNode.gain.value = volume;
    }
  }, [
    gainNode,
    state.self.data,
    state.users.data,
    state.groups.data,
    participant,
    soundEnabled,
    overallVolume,
    whisperVolume,
    proximityEnabled,
    distances,
    userVolumes,
    groupVolumes,
  ]);

  return (
    <div>
      {/* This muted element must exist to work around a chrome bug. The source is set above. */}
      <audio ref={audioRef} autoPlay muted />
    </div>
  );
}

export default Participant;
