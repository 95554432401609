import * as PIXI from 'pixi.js';
import { pixiState } from './state';

export const room = () => {
  const container = new PIXI.Container();
  container.sortableChildren = true;
  container.x = pixiState.app.screen.width / 2;
  container.y = pixiState.app.screen.height / 2;

  pixiState.app.stage.addChild(container);
  pixiState.room = container;
};
