import './Subtitle.css';

function Subtitle() {
  return (
    <>
      <h2 className="Subtitle">
        <span className="Subtitle-part Subtitle-voice">
          <i className="icon icon-mic" />
          VOICE
        </span>
        <span className="Subtitle-part Subtitle-video">
          <i className="icon icon-camera" />
          VIDEO
        </span>
        <span className="Subtitle-part Subtitle-text">
          <i className="icon icon-chat" />
          TEXT
        </span>
        <span className="Subtitle-part Subtitle-screen">
          <i className="icon icon-screen" />
          SCREEN SHARING
        </span>
      </h2>
    </>
  );
}

export default Subtitle;
