import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../modal/Modal';
import useCache from '../../lib/cache/context';
import { post } from '../../lib/client';
import './Invite.css';

function Invite({ setIsOpen }) {
  const { state } = useCache();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inviteLink, setInviteLink] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const onCopy = async () => {
    await navigator.clipboard.writeText(inviteLink);
    setCopied(true);
  };

  useEffect(() => {
    (async () => {
      const { token } = await post('/invite-token', { roomId: state.room.data.id });
      const url = `${window.location.protocol}//${window.location.host}`;
      setInviteLink(`${url}?inviteToken=${token}`);
    })();
  }, [state.room.data.id]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onInvite = async ({ name }) => {
    setSuccess('');
    setError('');
    try {
      await post(`/rooms/${state.room.data.id}/invite`, { name });
      setSuccess('Invite sent!');
      reset();
    } catch (err) {
      if (err.status === 404) {
        setError("Couldn't send invite. Double check the name.");
        return;
      }

      console.error(err);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setSuccess('');
    setError('');
    reset();
  };

  return (
    <Modal name="invite" title="Invite" close={onClose}>
      <form onSubmit={handleSubmit(onInvite)}>
        <div className="Invite-field">
          <label className="Invite-label">Username</label>
          <input
            disabled={isSubmitting}
            autoFocus
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <div className="error">Username required</div>}
          {!!error && <div className="error">{error}</div>}
          {!!success && <div className="success">{success}</div>}
        </div>
        <input
          disabled={isSubmitting}
          className="btn-primary"
          type="submit"
          value="Invite by username"
        />
      </form>
      <p className="Invite-link-description">Or, send an invite link (expires in 7 days):</p>
      <div className="Invite-link-container">
        {copied ? (
          <i className="icon icon-check" onClick={onCopy} />
        ) : (
          <i className="icon icon-copy" onClick={onCopy} />
        )}

        <div className="Invite-code-container">
          <code className="Invite-code">{inviteLink ? inviteLink : 'Loading...'}</code>
        </div>
      </div>
    </Modal>
  );
}

export default Invite;
