import { useState, useRef, useEffect, useCallback } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import './Grid.css';

const ReactGridLayout = WidthProvider(RGL);

export const maxCols = 18;
export const maxRows = 12;
const margin = 10;

function Grid({
  noDrag,
  hidden,
  layout,
  setLayout,
  disableContextMenu,
  afterAdjustment,
  children,
}) {
  const contianerRef = useRef(null);
  const layoutRef = useRef(null);

  const [rowHeight, setRowHeight] = useState(0);
  const [lastLegalLayout, setLastLegalLayout] = useState([]);
  const [classNames, setClassNames] = useState('');

  // Prevent the default right-click menu.
  useEffect(() => {
    if (disableContextMenu && contianerRef.current) {
      contianerRef.current.oncontextmenu = function (event) {
        event.preventDefault();
      };
    }
  }, [contianerRef, disableContextMenu]);

  // Prevent initial animation.
  useEffect(() => {
    const timer = setTimeout(() => setClassNames('loaded'), 500);
    return () => clearTimeout(timer);
  }, []);

  const calculateRowsAndCols = useCallback(() => {
    // Timeout needed to ensure that height is calculated after rendering is complete.
    setTimeout(() => {
      const containerHeight = contianerRef.current.offsetHeight;

      // Get the height of all margins added together.
      const marginCount = maxRows + 1;
      const marginSumHeight = marginCount * margin;

      // Subtract margins from container so they aren't included in the row height.
      const effectiveContainerHeight = containerHeight - marginSumHeight;
      const rowHeight = effectiveContainerHeight / maxRows;

      setRowHeight(rowHeight);
    }, 0);
  }, []);

  useEffect(() => {
    calculateRowsAndCols();
    window.addEventListener('resize', calculateRowsAndCols);
    return () => window.removeEventListener('resize', calculateRowsAndCols);
  }, [calculateRowsAndCols]);

  useEffect(() => {
    if (!layoutRef.current || !contianerRef.current) {
      return;
    }

    const layoutHeight = layoutRef.current.elementRef.current.offsetHeight;
    const containerHeight = contianerRef.current.offsetHeight;
    if (layoutHeight > containerHeight) {
      setLayout(lastLegalLayout);
    }
  }, [layout, setLayout, lastLegalLayout]);

  const onLayoutChange = (currentLayout) => {
    setLayout(currentLayout);
  };

  const onAdjustment = (currentLayout, oldItem, newItem) => {
    setLastLegalLayout(layout);

    if (typeof afterAdjustment === 'function') {
      afterAdjustment(newItem);
    }
  };

  let rglClassNames = classNames;
  if (hidden) {
    rglClassNames += ' Grid-hidden';
  }

  if (noDrag) {
    rglClassNames += ' Grid-no-drag';
  }

  return (
    <div className="Grid" ref={contianerRef}>
      <ReactGridLayout
        className={rglClassNames}
        ref={layoutRef}
        layout={layout}
        cols={maxCols}
        compactType={null}
        onLayoutChange={onLayoutChange}
        onResizeStart={onAdjustment}
        onDragStart={onAdjustment}
        rowHeight={rowHeight}
        maxRows={maxRows}
        margin={[margin, margin]}
        resizeHandles={['se', 'nw', 'ne', 'sw']}
        allowOverlap={true}
      >
        {children}
      </ReactGridLayout>
    </div>
  );
}

export default Grid;
