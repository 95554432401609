import * as self from './self';
import * as users from './users';
import * as room from './room';
import * as rooms from './rooms';
import * as groups from './groups';
import * as roomsUsers from './rooms-users';
import * as messages from './messages';
import * as keyBindings from './key-bindings';

export const initialState = {
  self: self.initialState,
  users: users.initialState,
  room: room.initialState,
  rooms: rooms.initialState,
  groups: groups.initialState,
  roomsUsers: roomsUsers.initialState,
  messages: messages.initialState,
  keyBindings: keyBindings.initialState,
};

const combineReducers = (slices) => (state, action) => {
  if (action.type === 'RESET') {
    return initialState;
  }

  return Object.keys(slices).reduce(
    (acc, prop) => ({
      ...acc,
      [prop]: slices[prop](acc[prop], action),
    }),
    state
  );
};

const reducer = combineReducers({
  self: self.reducer,
  users: users.reducer,
  room: room.reducer,
  rooms: rooms.reducer,
  groups: groups.reducer,
  roomsUsers: roomsUsers.reducer,
  messages: messages.reducer,
  keyBindings: keyBindings.reducer,
});

export default reducer;
