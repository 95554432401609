import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useCache from '../../../lib/cache/context';
import actions from '../../../lib/cache/actions';
import { IMAGE_REGEX } from '../../../lib/config';
import './ChatBox.css';

function ChatBox() {
  const { state, dispatch } = useCache();
  const [error, setError] = useState('');
  const messagesRef = useRef(null);

  const [fileName, setFileName] = useState('');
  const [dragging, setDragging] = useState(false);

  const {
    reset,
    register,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const scrollToBottom = () => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.scrollTop =
      messagesRef.current.scrollHeight - messagesRef.current.offsetHeight;
  };

  useEffect(() => {
    if (!messagesRef.current) {
      return;
    }

    messagesRef.current.scrollTop =
      messagesRef.current.scrollHeight - messagesRef.current.offsetHeight;
  }, [state.messages.data]);

  const stopPropagation = (event) => {
    event.stopPropagation();
    return false;
  };

  const onSend = async ({ message, file }) => {
    setError('');
    if (!message && !file.length) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('text', message);

    try {
      await actions.messages.create(dispatch, state.room.data.id, formData);
      setFileName('');
      reset();
    } catch (err) {
      if (err.status === 400 && err.message.includes('images only')) {
        setError('Can only send images.');
        return;
      }

      console.error(err);
      setError("Couldn't send message");
    }
  };

  const updateFileName = (event) => {
    if (!event.target.files.length) {
      return;
    }

    const { name } = event.target.files[0];
    setFileName(name.split(/(\\|\/)/g).pop());
  };

  const clearFile = () => {
    setFileName('');
    reset();
  };

  const renderMessage = (message) => {
    if (message.text.match(IMAGE_REGEX)) {
      return (
        <div className="ChatBox-image-message">
          <b>{message.userName}: </b>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img onLoad={scrollToBottom} src={message.text} alt="<broken image>" />
        </div>
      );
    }

    return (
      <span>
        <b>{message.userName}: </b>
        {message.text}
      </span>
    );
  };

  const onDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
    setError('');
  };

  const onDragLeave = (event) => {
    event.preventDefault();
    setDragging(false);
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event) => {
    if (!event.dataTransfer?.files?.length) {
      setDragging(false);
      setError('Can only send images.');
      return;
    }

    event.preventDefault();
    setValue('file', event.dataTransfer.files);
    const { name } = event.dataTransfer.files[0];
    setFileName(name.split(/(\\|\/)/g).pop());
    setDragging(false);
  };

  return (
    <div onDragEnter={onDragEnter} onDragOver={onDragOver} className="ChatBox">
      {dragging && (
        <div onDragLeave={onDragLeave} onDrop={onDrop} className="ChatBox-dragging">
          <i className="icon icon-upload" />
        </div>
      )}
      <div ref={messagesRef} className="ChatBox-messages">
        {state.messages.data.map((message) => (
          <div key={message.id} className="ChatBox-message" onMouseDown={stopPropagation}>
            {renderMessage(message)}
          </div>
        ))}
      </div>
      <form className="ChatBox-form" onSubmit={handleSubmit(onSend)}>
        {!!fileName ? (
          <span className="ChatBox-file-name" onMouseDown={stopPropagation}>
            <i className="icon icon-remove" onClick={clearFile} />
            <span>{fileName}</span>
          </span>
        ) : (
          <input
            disabled={isSubmitting}
            type="text"
            placeholder={`Message ${state.room.data.name}`}
            onMouseDown={stopPropagation}
            {...register('message', { required: false })}
          />
        )}
        <label htmlFor="file" className="file" onMouseDown={stopPropagation}>
          <i className="icon icon-upload" />
        </label>
        <input
          id="file"
          type="file"
          accept="image/*"
          {...register('file', { required: false, onChange: updateFileName })}
        />
        <input
          disabled={isSubmitting}
          onMouseDown={stopPropagation}
          className="btn-primary"
          type="submit"
          value="Send"
        />
      </form>
      {!!error && <div className="error ChatBox-error">{error}</div>}
    </div>
  );
}

export default ChatBox;
