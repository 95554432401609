import { useEffect, useState } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import useCache from '../../../lib/cache/context';

function Mute({ className }) {
  const self = useDyteSelector((m) => m.self);
  const { state } = useCache();

  const [muteBindPressed, setMuteBindPressed] = useState(false);
  const [unmuteBindPressed, setUnmuteBindPressed] = useState(false);

  useEffect(() => {
    (async () => {
      if (self.audioEnabled && state.keyBindings.active.includes('mute')) {
        setMuteBindPressed(true);
        await self.disableAudio();
      }

      if (muteBindPressed && !state.keyBindings.active.includes('mute')) {
        setMuteBindPressed(false);
        await self.enableAudio();
      }

      if (!self.audioEnabled && state.keyBindings.active.includes('unmute')) {
        setUnmuteBindPressed(true);
        await self.enableAudio();
      }

      if (unmuteBindPressed && !state.keyBindings.active.includes('unmute')) {
        setUnmuteBindPressed(false);
        await self.disableAudio();
      }
    })();
  }, [self, state.keyBindings.active, muteBindPressed, unmuteBindPressed]);

  const toggleMute = async () => {
    if (self.audioEnabled) {
      await self.disableAudio();
    } else {
      await self.enableAudio();
    }
  };

  return (
    <button className={`btn ${className}`} onClick={toggleMute}>
      {self.audioEnabled ? (
        <>
          <i className="icon icon-mic" /> Mute
        </>
      ) : (
        <>
          <i className="icon icon-mic-muted" /> Unmute
        </>
      )}
    </button>
  );
}

export default Mute;
