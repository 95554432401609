import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { PIXELS_PER_UNIT } from '../config';
import { pixiState } from './state';

const getPercentText = (str) => {
  const text = new PIXI.Text(str, { fill: 0xffffff, fontSize: 50 });
  text.scale.set(0.3);
  text.alpha = 0.3;
  return text;
};

export const drawPositioner = () => {
  const padding = 5;

  let volume = 0.1;
  let radius = Math.sqrt(1 / volume) * PIXELS_PER_UNIT;
  let circles = new Graphics();
  circles.beginFill(0xffffff, 0.1);
  circles.drawCircle(0, 0, radius);
  const text10 = getPercentText('10%');
  text10.x = radius - text10.width - padding;
  text10.y = -text10.height / 2;

  volume = 0.3;
  radius = Math.sqrt(1 / volume) * PIXELS_PER_UNIT;
  circles.beginFill(0xffffff, 0.1);
  circles.drawCircle(0, 0, radius);
  const text30 = getPercentText('30%');
  text30.x = radius - text30.width - padding;
  text30.y = -text30.height / 2;

  circles.beginFill(0xffffff, 0.1);
  circles.drawCircle(0, 0, PIXELS_PER_UNIT);
  const text100 = getPercentText('100%');
  text100.x = PIXELS_PER_UNIT - text100.width - padding;
  text100.y = -text100.height / 2;

  const container = new PIXI.Container();
  container.eventMode = 'none';
  container.show = false;
  container.alpha = 0;
  container.visible = true;
  container.addChild(circles);
  container.addChild(text10);
  container.addChild(text30);
  container.addChild(text100);

  pixiState.app.ticker.add((dt) => {
    if (container.show) {
      container.alpha = Math.min(1.0, container.alpha + 0.12 * dt);
    } else {
      container.alpha = Math.max(0, container.alpha - 0.12 * dt);
    }
  });

  return container;
};
