import { useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import { animateIndicator } from '../../../lib/canvas/indicator';
import Participant from './Participant';

function Audio({
  soundEnabled,
  overallVolume,
  userVolumes,
  groupVolumes,
  distances,
  whisperVolume,
  proximityEnabled,
}) {
  const participants = useDyteSelector((m) => m.participants.joined);
  const self = useDyteSelector((m) => m.self);

  // Check mic input.
  useEffect(() => {
    if (!self.audioTrack || !self.audioEnabled) {
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(self.audioTrack);

    const audioContext = new window.AudioContext();
    const analyser = audioContext.createAnalyser();
    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const removeAnimation = animateIndicator(analyser);

    return () => {
      removeAnimation();
      source.disconnect();
    };
  }, [self.audioEnabled, self.audioTrack]);

  return (
    <>
      {participants.toArray().map((p) => (
        <Participant
          key={p.id}
          participant={p}
          soundEnabled={soundEnabled}
          overallVolume={overallVolume}
          whisperVolume={whisperVolume}
          proximityEnabled={proximityEnabled}
          userVolumes={userVolumes}
          groupVolumes={groupVolumes}
          distances={distances}
        />
      ))}
    </>
  );
}

export default Audio;
