import { useDyteSelector } from '@dytesdk/react-web-core';

const canShareScreen = navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices;

function ShareScreen({ isElectron, className, setScreenPickerOpen }) {
  const self = useDyteSelector((m) => m.self);

  const toggleScreenShare = async () => {
    if (self.screenShareEnabled) {
      await self.disableScreenShare();
    } else if (isElectron) {
      setScreenPickerOpen(true);
    } else {
      await self.enableScreenShare();
    }
  };

  return (
    canShareScreen && (
      <button
        disabled={!self.roomJoined}
        className={`btn ${className}`}
        onClick={toggleScreenShare}
      >
        {self.screenShareEnabled ? (
          <>
            <i className="icon icon-no-screen" />
            Stop
          </>
        ) : (
          <>
            <i className="icon icon-screen" /> Screen
          </>
        )}
      </button>
    )
  );
}

export default ShareScreen;
