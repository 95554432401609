import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../modal/Modal';
import useCache from '../../lib/cache/context';
import { post } from '../../lib/client';
import './RoomSettings.css';

function RoomSettings({ setIsOpen }) {
  const { state } = useCache();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async ({ name }) => {
    setSuccess('');
    setError('');
    try {
      await post(`/rooms/${state.room.data.id}`, { name });
      setSuccess('Saved!');
      reset();
    } catch (err) {
      console.error(err);
      setError("Couldn't save.");
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setSuccess('');
    setError('');
    reset();
  };

  return (
    <Modal name="room-settings" title="Room Settings" close={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="RoomSettings-field">
          <label className="RoomSettings-label">Name</label>
          <input
            disabled={isSubmitting}
            autoFocus
            type="text"
            {...register('name', { required: true })}
          />
          {errors.name && <div className="error">Name required</div>}
          {!!error && <div className="error">{error}</div>}
          {!!success && <div className="success">{success}</div>}
        </div>
        <input disabled={isSubmitting} className="btn-primary" type="submit" value="Save" />
      </form>
    </Modal>
  );
}

export default RoomSettings;
