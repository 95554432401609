import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as socket from '../../lib/socket/socket';
import useCache from '../../lib/cache/context';
import actions from '../../lib/cache/actions';
import { post } from '../../lib/client';
import NewRoom from './new-room/NewRoom';
import RemoveRoom from './remove-room/RemoveRoom';
import UploadAvatar from './upload-avatar/UploadAvatar';
import ConnectDiscord from './connect-discord/ConnectDiscord';
import './Rooms.css';

function Rooms() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useCache();
  const [newRoomOpen, setNewRoomOpen] = useState(false);
  const [connectDiscordRoom, setConnectDiscordRoom] = useState(null);
  const [removeRoom, setRemoveRoom] = useState(null);
  const [joiningRoom, setJoiningRoom] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [roomsUsers, setRoomsUsers] = useState({});

  useEffect(() => {
    (async () => {
      await socket.connect(dispatch);
      await actions.rooms.fetch(dispatch);
      await actions.roomsUsers.fetch(dispatch);
    })();
  }, [dispatch]);

  useEffect(() => {
    const inviteToken = location.state?.inviteToken;
    if (inviteToken) {
      (async () => {
        try {
          const { user, room, roomUsers } = await post(`/invite-token/${inviteToken}`);
          if (room && roomUsers) {
            actions.self.update(dispatch, user);
            actions.rooms.update(dispatch, room);
            actions.roomsUsers.add(dispatch, roomUsers);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [location, dispatch]);

  useEffect(() => {
    const newRoomsUsers = {};
    for (const room of state.rooms.data) {
      newRoomsUsers[room.id] = [];
    }

    for (const user of state.roomsUsers.data) {
      if (user.id === state.self.data.id) {
        continue;
      }

      if (!newRoomsUsers[user.roomId]) {
        continue;
      }

      newRoomsUsers[user.roomId].push(user);
    }

    setRoomsUsers(newRoomsUsers);
  }, [state.self.data, state.rooms.data, state.roomsUsers.data]);

  const onJoin = (roomId) => {
    return async () => {
      setJoiningRoom(true);
      await actions.room.join(dispatch, roomId);
    };
  };

  const onLogout = async () => {
    await actions.self.logout(dispatch);
    navigate('/');
  };

  const onChangeAvatar = (event) => {
    if (!event.target.files.length) {
      return;
    }

    setAvatar(event.target.files[0]);
  };

  const getRoomUsers = (roomId) => {
    return roomsUsers[roomId] || [];
  };

  return (
    <>
      {!!avatar && <UploadAvatar avatar={avatar} setAvatar={setAvatar} />}
      {newRoomOpen && <NewRoom setNewRoomOpen={setNewRoomOpen} />}
      {!!removeRoom && <RemoveRoom removeRoom={removeRoom} setRemoveRoom={setRemoveRoom} />}
      {!!connectDiscordRoom && (
        <ConnectDiscord room={connectDiscordRoom} setRoom={setConnectDiscordRoom} />
      )}

      <div className="Rooms">
        <div className="Rooms-column">
          <div className="Rooms-header">
            <h1 className="Rooms-title">Voximity</h1>
            <div className="Rooms-account">
              {!state.self.data.avatar.startsWith('http') && (
                <label
                  className="Rooms-account-avatar"
                  style={{ backgroundColor: '#' + state.self.data.avatar }}
                  htmlFor="avatar"
                >
                  <i className="icon-unicorn" />
                </label>
              )}
              {state.self.data.avatar.startsWith('http') && (
                <label className="Rooms-account-avatar" htmlFor="avatar">
                  <img src={state.self.data.avatar} alt="avatar" />
                </label>
              )}
              <label className="Rooms-account-name" htmlFor="avatar">
                {state.self.data.name}
              </label>
              <input id="avatar" type="file" accept="image/*" onChange={onChangeAvatar} />
              <button className="btn" onClick={onLogout}>
                Logout
              </button>
            </div>
          </div>
          <div className="Rooms-community">
            <p>Have a question, concern, or feedback?</p>
            <a
              className="btn"
              href="https://discord.gg/qB2hG6XPMz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon icon-discord icon-bright" /> Join our Discord
            </a>
          </div>
          <div className="Rooms-main">
            <div className="Rooms-list-header">
              <h2 className="Rooms-subtitle">Rooms</h2>
              <button
                disabled={joiningRoom}
                className="btn-primary"
                onClick={() => setNewRoomOpen(true)}
              >
                Create room
              </button>
            </div>
            {!state.rooms.data.length && (
              <div className="Rooms-empty">No rooms. Create one to get started.</div>
            )}
            <div className="Rooms-list">
              {state.rooms.data.map((room) => (
                <div key={room.id} className="Rooms-item">
                  <div className="Rooms-item-header">
                    <div className="Rooms-item-name">
                      <div>{room.name}</div>
                      <div className="Rooms-online-count">
                        {getRoomUsers(room.id).length} online
                      </div>
                    </div>
                    <div className="Rooms-item-buttons">
                      <button
                        disabled={joiningRoom}
                        className="btn-primary"
                        onClick={onJoin(room.id)}
                      >
                        Join
                      </button>
                      <button
                        disabled={joiningRoom}
                        className="btn"
                        onClick={() => setConnectDiscordRoom(room)}
                      >
                        Connect Discord
                      </button>
                      <button
                        disabled={joiningRoom}
                        className="btn"
                        onClick={() => setRemoveRoom(room)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                  {!!getRoomUsers(room.id).length && (
                    <div className="Rooms-names">
                      {getRoomUsers(room.id)
                        .map((u) => u.name)
                        .join(', ')}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rooms;
