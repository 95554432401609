export const getGroup = (userId, users, groups) => {
  const user = users.find((u) => u.id === userId);
  if (!user || !user.groupId) {
    return null;
  }

  return groups.find((g) => g.id === user.groupId);
};

export const getWhisperChains = (users) => {
  const chains = [];

  const expandChain = (chain, user) => {
    for (const otherUser of users) {
      if (chain.includes(otherUser.id)) {
        continue;
      }

      if (
        user.whisperUserId === otherUser.id ||
        otherUser.whisperUserId === user.id ||
        (user.whisperGroupId && user.whisperGroupId === otherUser.groupId) ||
        (otherUser.whisperGroupId && otherUser.whisperGroupId === user.groupId)
      ) {
        chain.push(otherUser.id);
        expandChain(chain, otherUser);
      }
    }
  };

  for (const user of users) {
    const exists = chains.find((chain) => chain.includes(user.id));
    if (exists) {
      continue;
    }

    const chain = [user.id];
    expandChain(chain, user);
    if (chain.length < 2) {
      continue;
    }

    chains.push(chain);
  }

  return chains;
};
