import { useState, useEffect, useRef, useCallback } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import Modal from '../../../../components/modal/Modal';
import './Settings.css';

function Settings({
  isOpen,
  setIsOpen,
  setFeedbackOpen,
  setKeyBindingsOpen,
  overallVolume,
  setOverallVolume,
  whisperVolume,
  setWhisperVolume,
  proximityEnabled,
  setProximityEnabled,
}) {
  const self = useDyteSelector((m) => m.self);
  const [microphones, setMicrophones] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [isTesting, setIsTesting] = useState(false);
  const audioRef = useRef(null);
  const [micPermission, setMicPermission] = useState(false);

  const updateDevices = useCallback(async () => {
    const newCameras = [];
    const newMicrophones = [];
    const devices = await self.getAllDevices();
    for (const device of devices) {
      if (device.deviceId && device.kind === 'audioinput') {
        newMicrophones.push(device);
      }

      if (device.deviceId && device.kind === 'videoinput') {
        newCameras.push(device);
      }
    }

    setCameras(newCameras);
    setMicrophones(newMicrophones);
  }, [self]);

  useEffect(() => {
    updateDevices();
    if (navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = updateDevices;
    }
  }, [updateDevices]);

  // When settings are opened, check if the user has blocked or granted
  // microphone access. If it's granted, update devices.
  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => updateDevices())
      .then(() => setMicPermission(true))
      .catch(() => setMicPermission(false));
  }, [isOpen, updateDevices]);

  const selectMicrophone = async (event) => {
    for (const device of microphones) {
      if (device.deviceId === event.target.value) {
        audioRef.current.srcObject = null;
        await self.setDevice(device);
        setIsTesting(false);
      }
    }
  };

  const selectCamera = async (event) => {
    for (const device of cameras) {
      if (device.deviceId === event.target.value) {
        await self.setDevice(device);
      }
    }
  };

  const getMicrophoneId = () => {
    const current = self.getCurrentDevices();
    return current && current.audio && current.audio.deviceId;
  };

  const getCameraId = () => {
    const current = self.getCurrentDevices();
    return current && current.video && current.video.deviceId;
  };

  const toggleTest = () => {
    if (isTesting) {
      audioRef.current.srcObject = null;
      setIsTesting(false);
      return;
    }

    self.enableAudio().then(() => {
      const stream = new MediaStream();
      stream.addTrack(self.audioTrack);
      audioRef.current.srcObject = stream;
      setIsTesting(true);
    });
  };

  const openFeedback = () => {
    setIsOpen(false);
    setFeedbackOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    if (isTesting) {
      toggleTest();
    }
  };

  // const openKeyBindings = () => {
  //   setIsOpen(false);
  //   setKeyBindingsOpen(true);
  // };

  return (
    isOpen && (
      <Modal name="settings" title="Settings" close={close}>
        <div className="Settings-setting">
          <label className="Settings-label">Overall volume</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            className="Settings-slider"
            value={overallVolume}
            onChange={(e) => setOverallVolume(e.target.value)}
          />
        </div>

        <div className="Settings-setting" title="How loud others are when you're whispering.">
          <label className="Settings-label">Whisper volume 💡</label>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            className="Settings-slider"
            value={whisperVolume}
            onChange={(e) => setWhisperVolume(e.target.value)}
          />
        </div>

        <div className="Settings-setting" title="Enable to make farther users quieter.">
          <label className="Settings-label">Proximity effect 💡</label>
          <input
            type="checkbox"
            checked={proximityEnabled}
            onChange={() => setProximityEnabled(!proximityEnabled)}
          />
        </div>

        <div className="Settings-setting">
          <label className="Settings-label">Microphone</label>
          {micPermission && (
            <select
              className="Settings-select"
              onChange={selectMicrophone}
              value={getMicrophoneId()}
            >
              {microphones.map((m) => (
                <option key={m.deviceId} value={m.deviceId}>
                  {m.label}
                </option>
              ))}
            </select>
          )}
        </div>
        {micPermission && (
          <div className="Settings-subsetting">
            <span>Click test to hear how your mic sounds</span>
            <button className="Settings-test-button btn" onClick={toggleTest}>
              {isTesting ? 'Stop' : 'Test'}
            </button>
            <audio ref={audioRef} autoPlay />
          </div>
        )}
        {!micPermission && (
          <div className="Settings-subsetting">
            <span>
              Microphone access has been blocked. Change your browser settings to allow access.
            </span>
          </div>
        )}

        {self.videoEnabled && (
          <div className="Settings-setting">
            <label className="Settings-label">Camera</label>
            <select className="Settings-select" onChange={selectCamera} value={getCameraId()}>
              {cameras.map((c) => (
                <option key={c.deviceId} value={c.deviceId}>
                  {c.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* <div className="Settings-setting">
          <button className="btn-secondary" onClick={openKeyBindings}>
            <i className="icon icon-keyboard" /> Key bindings
          </button>
        </div> */}

        <div className="Settings-setting">
          <button className="btn-primary" onClick={openFeedback}>
            <i className="icon icon-write icon-dark" /> Send feedback
          </button>
        </div>
      </Modal>
    )
  );
}

export default Settings;
