import * as PIXI from 'pixi.js';
import { getGroup } from '../cache/helpers';
import { pixiState } from './state';
import { updateTextPositions } from './text';

export const updateGroups = (selfId, users, groups) => {
  const selfGroup = getGroup(selfId, users, groups);
  drawGroupName(pixiState.self, selfGroup);

  for (const [id, other] of Object.entries(pixiState.others)) {
    const group = getGroup(id, users, groups);
    drawGroupName(other, group);
  }
};

export const drawGroupName = (userDisplay, group) => {
  if (!userDisplay) {
    return;
  }

  if (!group && !userDisplay.group) {
    return;
  }

  if (!group && userDisplay.group) {
    userDisplay.group.destroy(true);
    delete userDisplay.group;
    updateTextPositions(userDisplay);
    return;
  }

  const color = Number('0x' + group.color.substring(1));
  if (userDisplay.group) {
    userDisplay.group.text = group.name;
    userDisplay.group.fill = color;
    return;
  }

  userDisplay.name.y = -userDisplay.name.height;
  const groupText = new PIXI.Text(group.name, {
    fill: color,
    fontSize: 28,
  });

  // Oversize, then scale down to maintain sharpness.
  groupText.scale.set(0.5);

  userDisplay.group = groupText;
  userDisplay.paralyzed.addChild(groupText);

  updateTextPositions(userDisplay);
};
