import Modal from '../../components/modal/Modal';
import './WebglWarning.css';

function WebglWarning({ isOpen, setIsOpen }) {
  return (
    isOpen && (
      <Modal name="webgl-warning" title="Could not render" close={() => setIsOpen(false)}>
        <b>Voximity requires WebGL</b>
        <br />
        <br />
        WebGL is enabled by default in modern browsers. But, it looks like you may have disabled it.
        Google how to enable WebGL for your browser, and re-join the room.
      </Modal>
    )
  );
}

export default WebglWarning;
