// Used for calculated volume of other users based
// on their distance.
export const PIXELS_PER_UNIT = 275;

// Radius of the dot that represents the user's position.
export const USER_RADIUS = 48;

// export const DEV_HOST = '192.168.1.246';
export const DEV_HOST = 'localhost';

export const IMAGE_REGEX = /^(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))$/g;
