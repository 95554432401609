import { get, post } from '../client';

export const initialState = {
  data: [],
  active: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_KEY_BINDINGS':
      return {
        ...state,
        data: payload.keyBindings,
      };
    case 'UPDATE_KEY_BINDINGS':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => d.id !== payload.keyBinding.id),
          {
            ...(state.data.find((d) => d.id === payload.keyBinding.id) || {}),
            ...payload.keyBinding,
          },
        ],
      };
    case 'ACTIVATE_KEY_BINDING':
      return {
        ...state,
        active: [...state.active, payload.name],
      };
    case 'DEACTIVATE_KEY_BINDING':
      return {
        ...state,
        active: [...state.active.filter((a) => a !== payload.name)],
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch) {
    const keyBindings = await get('/key-bindings');
    dispatch({
      type: 'FETCH_KEY_BINDINGS',
      payload: { keyBindings },
    });
  },
  async upsert(dispatch, data) {
    const keyBinding = await post('/key-bindings', data);
    dispatch({
      type: 'UPDATE_KEY_BINDINGS',
      payload: { keyBinding },
    });
  },
  activate(dispatch, name) {
    dispatch({
      type: 'ACTIVATE_KEY_BINDING',
      payload: { name },
    });
  },
  deactivate(dispatch, name) {
    dispatch({
      type: 'DEACTIVATE_KEY_BINDING',
      payload: { name },
    });
  },
};
