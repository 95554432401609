import * as PIXI from 'pixi.js';
import { LINE_SCALE_MODE, settings } from '@pixi/graphics-smooth';

import { pixiState, resetState } from './state';
import { pan, zoom, pinch } from './controls';
import { room } from './room';

// Keep line thickness as stage is scaled.
settings.LINE_SCALE_MODE = LINE_SCALE_MODE.NONE;

let clearPanListeners = () => {};
export const initializeCanvas = (node) => {
  // Avoid re-initializing.
  if (pixiState.app) {
    return;
  }

  const app = new PIXI.Application({
    // TODO(Melendez): This resize keeps the canvas smaller than the window.
    // It leaves lines that look like scrollbars. Probably because the resize
    // uses clientWidth rather than scrollWidth
    resizeTo: node,
    backgroundAlpha: 0,
    resolution: window.devicePixelRatio,
    autoDensity: true,
    antialias: true,
  });

  pixiState.app = app;

  app.stage.label = 'stage';
  app.stage.sortableChildren = true;
  app.stage.eventMode = 'static';
  app.stage.hitArea = app.screen;

  room();

  clearPanListeners = pan();
  zoom();
  pinch();

  // Prevent the default right-click menu.
  node.oncontextmenu = function (event) {
    event.preventDefault();
  };

  node.appendChild(app.view);
};

export const destroyCanvas = () => {
  clearPanListeners();
  if (pixiState.app) {
    // Unlike Container.destroy() which has 1 parameter,
    // Application.destroy() has 2 parameters. Passing true
    // twice ensures the canvas element is removed and that
    // all children and textures are destroyed.
    pixiState.app.destroy(true, true);
  }

  resetState();
};
