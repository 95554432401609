import { pixiState } from './state';

export const updatePositions = (selfId, users, setDistances) => {
  const self = users.find((u) => u.id === selfId);
  if (!self) {
    return;
  }

  const x = self.x;
  const y = self.y;

  const distances = {};
  for (const user of users) {
    if (user.id === self.id) {
      continue;
    }

    const otherX = user.x;
    const otherY = user.y;
    const other = pixiState.others[user.id];
    if (other) {
      other.container.x = otherX;
      other.container.y = otherY;
    }

    distances[user.id] = getDistance(x, y, otherX, otherY);
  }

  setDistances(distances);
};

const getDistance = (x1, y1, x2, y2) => {
  const xDiff = x2 - x1;
  const yDiff = y2 - y1;
  const xSquared = xDiff * xDiff;
  const ySquared = yDiff * yDiff;
  return Math.sqrt(xSquared + ySquared);
};
