import { get, post } from '../client';
import * as socket from '../socket/socket';

export const initialState = {
  data: null,
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_SELF':
      return {
        ...state,
        data: payload.self,
      };
    case 'UPDATE_SELF':
      return {
        ...state,
        data: payload.self,
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch) {
    const self = await get('/self');
    dispatch({
      type: 'FETCH_SELF',
      payload: { self },
    });
  },
  async signup(dispatch, data) {
    const self = await post('/signup', data);
    dispatch({
      type: 'UPDATE_SELF',
      payload: { self },
    });
  },
  async login(dispatch, data) {
    const self = await post('/login', data);
    dispatch({
      type: 'UPDATE_SELF',
      payload: { self },
    });
  },
  async resetPassword(dispatch, data) {
    await post('/reset-password', data);
  },
  async newPassword(dispatch, data) {
    await post('/new-password', data);
  },
  async logout(dispatch) {
    socket.disconnect();
    await post('/logout');
    dispatch({ type: 'RESET' });
  },
  async uploadAvatar(dispatch, formData) {
    const self = await post('/avatar', formData);
    dispatch({
      type: 'UPDATE_SELF',
      payload: { self },
    });
  },
  update(dispatch, self) {
    dispatch({
      type: 'UPDATE_SELF',
      payload: { self },
    });
  },
};
