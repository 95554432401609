import { get, post } from '../client';

export const initialState = {
  data: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_GROUPS':
      return {
        ...state,
        data: payload.groups,
      };
    case 'UPDATE_GROUPS':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => d.id !== payload.group.id),
          {
            ...(state.data.find((d) => d.id === payload.group.id) || {}),
            ...payload.group,
          },
        ],
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch, roomId) {
    const groups = await get(`/rooms/${roomId}/groups`);
    dispatch({
      type: 'FETCH_GROUPS',
      payload: { groups },
    });

    return groups;
  },
  async create(dispatch, roomId, { name, color }) {
    const group = await post(`/rooms/${roomId}/groups`, { name, color });
    dispatch({
      type: 'UPDATE_GROUPS',
      payload: { group },
    });

    return group;
  },
  async isolate(dispatch, roomId, groupId) {
    const group = await post(`/rooms/${roomId}/groups/${groupId}`, { isIsolated: true });
    dispatch({
      type: 'UPDATE_GROUPS',
      payload: { group },
    });

    return;
  },
  async unisolate(dispatch, roomId, groupId) {
    const group = await post(`/rooms/${roomId}/groups/${groupId}`, { isIsolated: false });
    dispatch({
      type: 'UPDATE_GROUPS',
      payload: { group },
    });

    return;
  },
  update(dispatch, group) {
    dispatch({
      type: 'UPDATE_GROUPS',
      payload: { group },
    });
  },
};
