import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { pixiState } from './state';
import { USER_RADIUS } from '../config';

const amplitude = (analyser) => {
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  analyser.getByteTimeDomainData(dataArray);

  // Calculate the average amplitude
  let sum = 0;
  for (let i = 0; i < bufferLength; i++) {
    sum += Math.abs(dataArray[i] - 128);
  }

  const averageAmplitude = sum / bufferLength;
  return averageAmplitude;
};

export const animateIndicator = (analyser, otherId) => {
  if (!pixiState.app) {
    return () => {};
  }

  let display = null;
  const getDisplay = () => {
    if (!display) {
      if (!otherId) {
        return pixiState.self;
      } else {
        const other = pixiState.others[otherId];
        if (other) {
          return other;
        }
      }
    }
  };

  const minScale = 1;
  const maxScale = 2;
  const animate = (time) => {
    if (!display) {
      display = getDisplay();
      if (!display) {
        return;
      }
    }

    const a = amplitude(analyser);
    const newScale = Math.min(minScale + a / 2, maxScale);
    const oldScale = display.indicator.scale.x;
    if (newScale > oldScale) {
      display.indicator.scale.set(newScale);
    } else if (oldScale > minScale && newScale < maxScale) {
      display.indicator.scale.set(oldScale - 0.03);
    }
  };

  pixiState.app.ticker.add(animate);
  return () => {
    if (pixiState.app) {
      pixiState.app.ticker.remove(animate);
      if (display && !display.destroyed) {
        display.indicator.scale.set(1);
      }
    }
  };
};

export const drawIndicator = () => {
  const indicator = new Graphics();
  indicator.beginFill(0xe65397, 0.3);
  indicator.drawCircle(0, 0, USER_RADIUS - 14);
  return indicator;
};
