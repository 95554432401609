import { useState, useEffect } from 'react';
import Modal from '../../../components/modal/Modal';
import './ConnectDiscord.css';

function ConnectDiscord({ room, setRoom }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 3000);
    }
  }, [copied]);

  const onCopy = async () => {
    await navigator.clipboard.writeText(room.id);
    setCopied(true);
  };

  return (
    <Modal name="connect-discord" title="Connect Discord" close={() => setRoom(null)}>
      <div className="ConnectDiscord-description">
        Connect to a Discord server to show who's currently in the room.
      </div>
      <br />
      <div>
        <b>First</b> invite the Discord bot to your server:
      </div>
      <a
        className="ConnectDiscord-invite-button btn-primary"
        target="_blank"
        rel="noreferrer"
        href="https://discord.com/api/oauth2/authorize?client_id=1233906738370183270&permissions=59408&scope=bot"
      >
        Invite bot
      </a>
      <br />
      <br />
      <div>
        <b>Next</b> run the <code>/connect</code> command in your Discord server with the following{' '}
        <code>room_id</code>:
      </div>
      <div className="ConnectDiscord-command-container">
        {copied ? (
          <i className="icon icon-check" onClick={onCopy} />
        ) : (
          <i className="icon icon-copy" onClick={onCopy} />
        )}

        <div className="ConnectDiscord-code-container">
          <code className="ConnectDiscord-code">{room.id}</code>
        </div>
      </div>
    </Modal>
  );
}

export default ConnectDiscord;
