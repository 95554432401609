import { useState } from 'react';
import Grid, { maxRows } from '../../../components/grid/Grid';
import ChatBox from './ChatBox';
import './ChatGrid.css';

const initialWidth = 7;
const initialHeight = 5;

function ChatGrid({ barSwitch }) {
  const [layout, setLayout] = useState([
    {
      i: 'chat',
      x: 0,
      y: maxRows - initialHeight,
      w: initialWidth,
      h: initialHeight,
    },
  ]);

  return (
    <Grid layout={layout} setLayout={setLayout} noDrag={true} hidden={barSwitch !== 'chat'}>
      <div key="chat" className="ChatGrid-item">
        <ChatBox />
      </div>
    </Grid>
  );
}

export default ChatGrid;
