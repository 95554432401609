import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useCache from '../../../lib/cache/context';
import actions from '../../../lib/cache/actions';
import Modal from '../../../components/modal/Modal';

import './Login.css';

function Login({ form, setForm, inviteToken, resetPasswordToken, fakeAudio, isElectron }) {
  const navigate = useNavigate();
  const { dispatch } = useCache();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const signupForm = useForm();
  const loginForm = useForm();
  const resetForm = useForm();
  const newPasswordForm = useForm();

  const onSignup = async (data) => {
    if (inviteToken) {
      data.inviteToken = inviteToken;
    }

    try {
      await actions.self.signup(dispatch, data);
      window.history.pushState('', '', '/'); // Ensure that the back-button works after redirect.
      navigate('/app', { state: { inviteToken } });
    } catch (err) {
      if (err.message) {
        setError(err.message);
        return;
      }

      console.error(err);
      setError('Could not signup.');
    }
  };

  const onLogin = async ({ nameOrEmail, password }) => {
    try {
      await actions.self.login(dispatch, { nameOrEmail, password });
      navigate('/app', { state: { fakeAudio } });
    } catch (err) {
      if (err.message) {
        setError(err.message);
        return;
      }

      console.error(err);
      setError('Could not login.');
    }
  };

  const onReset = async ({ email }) => {
    setSuccess(false);
    setError('');

    try {
      await actions.self.resetPassword(dispatch, { email });
      setSuccess(true);
    } catch (err) {
      if (err.message) {
        setError(err.message);
        return;
      }

      console.error(err);
      setError('Could not reset password.');
    }
  };

  const onNewPassword = async ({ password }) => {
    setSuccess(false);
    setError('');

    try {
      await actions.self.newPassword(dispatch, { password, resetPasswordToken });
      setSuccess(true);
    } catch (err) {
      if (err.message) {
        setError(err.message);
        return;
      }

      console.error(err);
      setError('Could not update password.');
    }
  };

  const changeForm = (name) => {
    setError('');
    setSuccess(false);
    setForm(name);
  };

  const getTitle = (formName) => {
    if (formName === 'login') {
      return 'Login';
    } else if (formName === 'signup') {
      return 'Signup';
    } else if (formName === 'reset') {
      return 'Reset Password';
    } else if (formName === 'new-password') {
      return 'New Password';
    }
  };

  return (
    <Modal name="login" title={getTitle(form)} close={() => setForm('')} noClose={isElectron}>
      {form === 'signup' && (
        <form onSubmit={signupForm.handleSubmit(onSignup)}>
          <div>
            <label className="Login-label">Email</label>
            <input
              autoFocus
              type="text"
              disabled={signupForm.formState.isSubmitting}
              {...signupForm.register('email', {
                required: 'required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Invalid email.',
                },
              })}
            />
            {signupForm.formState.errors.email && (
              <div className="error">{signupForm.formState.errors.email.message}</div>
            )}
          </div>
          <br />
          <div>
            <label className="Login-label">Username</label>
            <input
              type="text"
              disabled={signupForm.formState.isSubmitting}
              {...signupForm.register('name', { required: true })}
            />
            {signupForm.formState.errors.name && <div className="error">Name required.</div>}
          </div>
          <br />
          <div>
            <label className="Login-label">Password</label>
            <input
              type="password"
              disabled={signupForm.formState.isSubmitting}
              {...signupForm.register('password', { required: true })}
            />
            {signupForm.formState.errors.password && <div className="error">Password required</div>}
          </div>
          <br />
          <div>
            <label className="Login-label">Repeat password</label>
            <input
              type="password"
              disabled={signupForm.formState.isSubmitting}
              {...signupForm.register('repeatPassword', {
                required: true,
                validate: (val) => {
                  if (signupForm.watch('password') !== val) {
                    return 'Passwords do not match.';
                  }
                },
              })}
            />
            {signupForm.formState.errors.repeatPassword && (
              <div className="error">{signupForm.formState.errors.repeatPassword.message}</div>
            )}
          </div>
          <br />
          {!!error && <div className="error">{error}</div>}
          <button
            type="button"
            className="Login-button btn-secondary"
            disabled={signupForm.formState.isSubmitting}
            onClick={() => changeForm('login')}
          >
            Login instead
          </button>
          <input
            className="btn-primary"
            type="submit"
            value="Signup"
            disabled={signupForm.formState.isSubmitting}
          />
        </form>
      )}
      {form === 'login' && (
        <form onSubmit={loginForm.handleSubmit(onLogin)}>
          <div>
            <label className="Login-label">Email or username</label>
            <input
              autoFocus
              type="text"
              disabled={loginForm.formState.isSubmitting}
              {...loginForm.register('nameOrEmail', { required: true })}
            />
            {loginForm.formState.errors.nameOrEmail && (
              <div className="error">Email or username required</div>
            )}
          </div>
          <br />
          <div>
            <label className="Login-label">Password</label>
            <input
              type="password"
              disabled={loginForm.formState.isSubmitting}
              {...loginForm.register('password', { required: true })}
            />
            {loginForm.formState.errors.password && <div className="error">Password required</div>}
          </div>
          <br />
          <button type="button" className="link" onClick={() => changeForm('reset')}>
            Reset password
          </button>
          <br />
          <br />
          {!!error && <div className="error">{error}</div>}
          <button
            type="button"
            className="Login-button btn-secondary"
            disabled={loginForm.formState.isSubmitting}
            onClick={() => changeForm('signup')}
          >
            Signup instead
          </button>
          <input
            className="btn-primary"
            type="submit"
            value="Login"
            disabled={loginForm.formState.isSubmitting}
          />
        </form>
      )}
      {form === 'reset' && (
        <form onSubmit={resetForm.handleSubmit(onReset)}>
          <div>
            <label className="Login-label">Email</label>
            <input
              autoFocus
              type="text"
              disabled={resetForm.formState.isSubmitting}
              {...resetForm.register('email', { required: true })}
            />
            {resetForm.formState.errors.email && <div className="error">Email required</div>}
          </div>
          <br />
          {success && <div className="success">Sent!</div>}
          {!!error && <div className="error">{error}</div>}
          <input
            className="btn-primary"
            type="submit"
            value="Reset password"
            disabled={resetForm.formState.isSubmitting}
          />
        </form>
      )}
      {form === 'new-password' && (
        <form onSubmit={newPasswordForm.handleSubmit(onNewPassword)}>
          <div>
            <label className="Login-label">New Password</label>
            <input
              type="password"
              disabled={success || newPasswordForm.formState.isSubmitting}
              {...newPasswordForm.register('password', { required: true })}
            />
            {newPasswordForm.formState.errors.password && (
              <div className="error">Password required</div>
            )}
          </div>
          <br />
          <div>
            <label className="Login-label">Repeat password</label>
            <input
              type="password"
              disabled={success || newPasswordForm.formState.isSubmitting}
              {...newPasswordForm.register('repeatPassword', {
                required: true,
                validate: (val) => {
                  if (newPasswordForm.watch('password') !== val) {
                    return 'Passwords do not match.';
                  }
                },
              })}
            />
            {newPasswordForm.formState.errors.repeatPassword && (
              <div className="error">{newPasswordForm.formState.errors.repeatPassword.message}</div>
            )}
          </div>
          <br />
          {success && <div className="success">Updated!</div>}
          {!!error && <div className="error">{error}</div>}
          {success && (
            <button
              type="button"
              className="Login-button btn-secondary"
              disabled={newPasswordForm.formState.isSubmitting}
              onClick={() => changeForm('login')}
            >
              Back to login
            </button>
          )}
          {!success && (
            <input
              className="btn-primary"
              type="submit"
              value="Update password"
              disabled={newPasswordForm.formState.isSubmitting}
            />
          )}
        </form>
      )}
    </Modal>
  );
}

export default Login;
