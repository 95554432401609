import { useState } from 'react';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import * as dyte from '../../../lib/dyte';
import actions from '../../../lib/cache/actions';
import useCache from '../../../lib/cache/context';
import Invite from '../../../components/invite/Invite';
import RoomSettings from '../../../components/room-settings/RoomSettings';
import { destroyCanvas } from '../../../lib/canvas/canvas';
import Settings from './settings/Settings';
import NewGroup from './NewGroup';
import Feedback from './settings/Feedback';
import KeyBindings from './settings/KeyBindings';
import ScreenPicker from './screen-picker/ScreenPicker';
import Mute from './Mute';
import Group from './Group';
import ShareScreen from './ShareScreen';
import Camera from './Camera';
import './Bar.css';

function Bar({
  isElectron,
  setPins,
  pinsHidden,
  setPinsHidden,
  barSwitch,
  setBarSwitch,
  overallVolume,
  setOverallVolume,
  whisperVolume,
  setWhisperVolume,
  proximityEnabled,
  setProximityEnabled,
}) {
  const { meeting } = useDyteMeeting();
  const { dispatch } = useCache();

  const [settingsOpen, setSettingsOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [newGroupOpen, setNewGroupOpen] = useState(false);
  const [inviteOpen, setInviteOpen] = useState(false);
  const [roomSettingsOpen, setRoomSettingsOpen] = useState(false);
  const [keyBindingsOpen, setKeyBindingsOpen] = useState(false);
  const [screenPickerOpen, setScreenPickerOpen] = useState(false);

  const leaveRoom = () => {
    actions.room.remove(dispatch);
    destroyCanvas();
    dyte.leaveMeeting(meeting);
  };

  const clearPins = () => {
    setPins({});
  };

  const onBarSelect = (mode) => {
    if (mode === barSwitch) {
      setBarSwitch(null);
    } else {
      setBarSwitch(mode);
    }
  };

  return (
    <div className="Bar">
      <Settings
        isOpen={settingsOpen}
        setIsOpen={setSettingsOpen}
        setFeedbackOpen={setFeedbackOpen}
        setKeyBindingsOpen={setKeyBindingsOpen}
        overallVolume={overallVolume}
        setOverallVolume={setOverallVolume}
        whisperVolume={whisperVolume}
        setWhisperVolume={setWhisperVolume}
        proximityEnabled={proximityEnabled}
        setProximityEnabled={setProximityEnabled}
      />

      {feedbackOpen && <Feedback setIsOpen={setFeedbackOpen} />}
      {newGroupOpen && <NewGroup setIsOpen={setNewGroupOpen} />}
      {inviteOpen && <Invite setIsOpen={setInviteOpen} />}
      {roomSettingsOpen && <RoomSettings setIsOpen={setRoomSettingsOpen} />}
      {keyBindingsOpen && <KeyBindings setIsOpen={setKeyBindingsOpen} />}
      {screenPickerOpen && <ScreenPicker setIsOpen={setScreenPickerOpen} />}

      <div className="Bar-mobile-open Bar-mobile-only">
        <div className="Bar-mobile-menu-header">
          {barSwitch === 'chat' && <span></span>}
          {barSwitch === 'room' && (
            <span>
              <button className="btn" onClick={() => setRoomSettingsOpen(true)}>
                <i className="icon icon-rename" /> Room name
              </button>
              <button className="btn" onClick={() => setInviteOpen(true)}>
                <i className="icon icon-invite" /> Invite
              </button>
            </span>
          )}
          {barSwitch === 'group' && (
            <span>
              <Group setNewGroupOpen={setNewGroupOpen} />
            </span>
          )}
          {barSwitch === 'pins' && (
            <span>
              <button className="btn" onClick={() => setPinsHidden(!pinsHidden)}>
                {pinsHidden ? (
                  <>
                    <i className="icon icon-show" /> Show all
                  </>
                ) : (
                  <>
                    <i className="icon icon-hide" /> Hide all
                  </>
                )}
              </button>
              <button className="btn" onClick={clearPins}>
                <i className="icon icon-unpin" /> Clear all
              </button>
            </span>
          )}
        </div>
        <div className="Bar-mobile-buttons">
          <div className="Bar-mobile-row">
            <span>
              <button
                className={barSwitch === 'chat' ? 'btn-secondary' : 'btn'}
                onClick={() => onBarSelect('chat')}
              >
                <i className="icon icon-chat" /> Chat
              </button>
              <button
                className={barSwitch === 'room' ? 'btn-secondary' : 'btn'}
                onClick={() => onBarSelect('room')}
              >
                <i className="icon icon-room" /> Room
              </button>
              <button
                className={barSwitch === 'group' ? 'btn-secondary' : 'btn'}
                onClick={() => onBarSelect('group')}
              >
                <i className="icon icon-group" /> Group
              </button>
              <button
                className={barSwitch === 'pins' ? 'btn-secondary' : 'btn'}
                onClick={() => onBarSelect('pins')}
              >
                <i className="icon icon-pin" /> Pins
              </button>
            </span>
            <span className="Bar-mobile-row-label"></span>
          </div>
          <div className="Bar-mobile-row">
            <span>
              <Mute />
              <Camera />
              <ShareScreen isElectron={isElectron} setScreenPickerOpen={setScreenPickerOpen} />
              <button className="btn" onClick={leaveRoom}>
                <i className="icon icon-door" /> Exit
              </button>
            </span>
            <span>
              <button className="btn" onClick={() => setSettingsOpen(!settingsOpen)}>
                <i className="icon icon-cog" /> Settings
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="Bar-desktop Bar-desktop-only">
        <div className="Bar-desktop-header">
          {barSwitch === 'room' && (
            <span>
              <button className="btn" onClick={() => setRoomSettingsOpen(true)}>
                <i className="icon icon-rename" /> Room name
              </button>
              <button className="btn" onClick={() => setInviteOpen(true)}>
                <i className="icon icon-invite" /> Invite
              </button>
            </span>
          )}
          {barSwitch === 'group' && (
            <span>
              <Group setNewGroupOpen={setNewGroupOpen} />
            </span>
          )}
          {barSwitch === 'pins' && (
            <span>
              <button className="btn" onClick={() => setPinsHidden(!pinsHidden)}>
                {pinsHidden ? (
                  <>
                    <i className="icon icon-show" /> Show all
                  </>
                ) : (
                  <>
                    <i className="icon icon-hide" /> Hide all
                  </>
                )}
              </button>
              <button className="btn" onClick={clearPins}>
                <i className="icon icon-unpin" /> Clear all
              </button>
            </span>
          )}
        </div>
        <div className="Bar-desktop-main">
          <span>
            <button
              className={`Bar-button ${barSwitch === 'chat' ? 'btn-secondary' : 'btn'}`}
              onClick={() => onBarSelect('chat')}
            >
              <i className="icon icon-chat" /> Chat
            </button>
            <button
              className={`Bar-button ${barSwitch === 'room' ? 'btn-secondary' : 'btn'}`}
              onClick={() => onBarSelect('room')}
            >
              <i className="icon icon-room" /> Room
            </button>
            <button
              className={`Bar-button ${barSwitch === 'group' ? 'btn-secondary' : 'btn'}`}
              onClick={() => onBarSelect('group')}
            >
              <i className="icon icon-group" /> Group
            </button>
            <button
              className={`Bar-button ${barSwitch === 'pins' ? 'btn-secondary' : 'btn'}`}
              onClick={() => onBarSelect('pins')}
            >
              <i className="icon icon-pin" /> Pins
            </button>
          </span>

          <span className="Bar-buttons">
            <Mute className="Bar-button" />
            <Camera className="Bar-button" />
            <ShareScreen
              className="Bar-button"
              isElectron={isElectron}
              setScreenPickerOpen={setScreenPickerOpen}
            />
            <button className="Bar-button btn" onClick={leaveRoom}>
              <i className="icon icon-door" /> Exit
            </button>
            <button className="Bar-button btn" onClick={() => setSettingsOpen(!settingsOpen)}>
              <i className="icon icon-cog" /> Settings
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Bar;
