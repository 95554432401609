import { useState, useEffect, useRef } from 'react';
import { useDyteClient, DyteProvider } from '@dytesdk/react-web-core';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import * as sentry from '../lib/sentry';
import actions from '../lib/cache/actions';
import useCache from '../lib/cache/context';
import Updater from './updater/Updater';
import Landing from './landing/Landing';
import Inactive from './inactive/Inactive';
import Rooms from './rooms/Rooms';
import Room from './room/Room';
import './App.css';

function App() {
  const { dispatch } = useCache();
  const [dataFetched, setDataFetched] = useState(false);
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(' electron/') > -1) {
      setIsElectron(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        await actions.self.fetch(dispatch);
      } catch (error) {
        console.error(error);
      } finally {
        setDataFetched(true);
      }
    })();
  }, [dispatch]);

  // Test error for Sentry.
  useEffect(() => {
    const onThrow = (event) => {
      if (event.ctrlKey && event.key === 'e') {
        throw new Error('This is just a test error :)');
      }
    };

    window.addEventListener('keydown', onThrow);
    return () => window.removeEventListener('keydown', onThrow);
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage isElectron={isElectron} dataFetched={dataFetched} />} />
        <Route
          path="/app"
          element={<ActualApp isElectron={isElectron} dataFetched={dataFetched} />}
        />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </div>
  );
}

function HomePage({ isElectron, dataFetched }) {
  return dataFetched && <Landing isElectron={isElectron} />;
}

function ActualApp({ isElectron, dataFetched }) {
  const { state, dispatch } = useCache();
  const [meeting, initMeeting] = useDyteClient({ resetOnLeave: true });
  const [isUpdating, setIsUpdating] = useState(true);
  const location = useLocation();
  const leaveSoundRef = useRef(null);

  const navigate = useNavigate();
  if (dataFetched && !state.self.data) {
    navigate('/');
  }

  useEffect(() => {
    if (state.room.data) {
      initMeeting({
        authToken: state.room.data.token,
        defaults: {
          audio: true,
          video: false,
          mediaConfiguration: {
            screenshare: {
              height: { max: 720, ideal: 720 },
              width: { max: 1280, ideal: 1280 },
              frameRate: { max: 30, ideal: 30 },
            },
            video: {
              height: { ideal: 720 },
              width: { ideal: 1280 },
              frameRate: { ideal: 30 },
            },
          },
        },
      }).catch((err) => {
        sentry.reportError(err);
        actions.room.remove(dispatch);
      });
    }

    // Ignore exhaustive-deps here because initMeeting is coming from a separate library.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.room.data]);

  useEffect(() => {
    if (meeting && meeting.self) {
      meeting.self.on('roomLeft', ({ state }) => {
        if (state === 'left') {
          if (leaveSoundRef.current) {
            leaveSoundRef.current.play().catch(sentry.reportError);
          }
        }
      });
    }
  }, [meeting, leaveSoundRef]);

  useEffect(() => {
    const audio = location.state?.fakeAudio;
    if (!audio) {
      return;
    }

    navigator.mediaDevices.getUserMedia = () => {
      const audioContext = new AudioContext();
      return fetch(`/audio/fake/${audio}.mp3`)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer))
        .then((audioBuffer) => {
          const audioBufferSourceNode = audioContext.createBufferSource();
          const mediaStreamAudioDestinationNode = audioContext.createMediaStreamDestination();
          audioBufferSourceNode.buffer = audioBuffer;
          audioBufferSourceNode.loop = true;
          audioBufferSourceNode.start();
          audioBufferSourceNode.connect(mediaStreamAudioDestinationNode);
          return mediaStreamAudioDestinationNode.stream;
        });
    };
  }, [location.state]);

  return (
    dataFetched && (
      <>
        {isElectron && isUpdating && (
          <Updater isUpdating={isUpdating} setIsUpdating={setIsUpdating} />
        )}
        {!(isElectron && isUpdating) && (
          <>
            {!state.room.data &&
              !!state.self.data &&
              (!state.self.data.activatedAt ? <Inactive /> : <Rooms />)}
            {!!state.room.data && (
              <>
                {/*           
                  Can't determine when meeting has loaded so just 
                  show this backdrop instead of a blank screen.
                */}
                <div className="App-loading">Loading...</div>

                <DyteProvider value={meeting}>
                  <Room isElectron={isElectron} />
                </DyteProvider>
              </>
            )}

            {isElectron && <div className="title-bar"></div>}
          </>
        )}
        <audio ref={leaveSoundRef} src="/audio/effects/leave.mp3" />
      </>
    )
  );
}

export default App;
