import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { pixiState } from './state';
import { USER_RADIUS } from '../config';

export const disableOtherVideo = (userId) => {
  const other = pixiState.others[userId];
  if (other) {
    disableVideo(other);
  }
};

export const disableSelfVideo = () => {
  disableVideo(pixiState.self);
};

export const enableOtherVideo = async (userId, ref, height) => {
  const other = pixiState.others[userId];
  if (other) {
    await enableVideo(other, ref, height);
  }
};

export const enableSelfVideo = async (ref, height) => {
  await enableVideo(pixiState.self, ref, height);
};

const disableVideo = (display) => {
  if (!display || !display.video) {
    return;
  }

  display.circle.visible = true;
  display.video.destroy(true);
  delete display.video;
};

const enableVideo = async (display, ref, height) => {
  if (display.video) {
    display.video.destroy(true);
    delete display.video;
  }

  const source = new PIXI.VideoResource(ref.current);
  source.updateFPS = 30;
  const texture = PIXI.Texture.from(source);
  const sprite = new PIXI.Sprite(texture);
  sprite.anchor.set(0.5);
  sprite.scale.set((2 * USER_RADIUS) / height);

  const circle = new Graphics();
  circle.beginFill(0x000000, 1);
  circle.drawCircle(0, 0, USER_RADIUS);

  const container = new PIXI.Container();
  container.zIndex = 100;
  container.addChild(circle);
  container.addChild(sprite);
  container.mask = circle;
  container.eventMode = 'static';

  display.paralyzed.addChild(container);
  display.video = container;

  display.circle.visible = false;
};
