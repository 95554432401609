import { USER_RADIUS } from '../config';

export const updateTextPositions = (userDisplay) => {
  userDisplay.name.x = -userDisplay.name.width / 2;
  userDisplay.name.y = USER_RADIUS;
  if (userDisplay.group) {
    userDisplay.group.x = -userDisplay.group.width / 2;
    userDisplay.group.y = USER_RADIUS + userDisplay.group.height;
  }
};
