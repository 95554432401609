import { AnimatedGIF } from '@pixi/gif';
import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { pixiState } from './state';
import { USER_RADIUS, IMAGE_REGEX } from '../config';

export const updateMessages = async (selfId, messages) => {
  const doneUserIds = [];
  const now = new Date().getTime();
  for (let i = messages.length - 1; i >= 0; i--) {
    const message = messages[i];
    if (doneUserIds.includes(message.userId)) {
      continue;
    }

    doneUserIds.push(message.userId);

    const createdAt = new Date(message.createdAt).getTime();
    if (now - createdAt > 5000) {
      break;
    }

    let display = pixiState.self;
    if (message.userId !== selfId) {
      display = pixiState.others[message.userId];
      if (!display) {
        continue;
      }
    }

    clearTimeout(display.messageTimer);
    const oldMessage = display.message;
    display.message = await drawMessage(message.text);
    display.paralyzed.addChild(display.message);

    if (oldMessage) {
      oldMessage.destroy(true);
    }

    display.messageTimer = setTimeout(() => {
      display.message.visible = false;
    }, 5000);
  }
};

const imageHeight = 220;
const textWidth = 500;
const textPadding = 10;
const caretX = 40;
const caretHeight = 20;

const drawMessage = async (msg) => {
  let content = null;
  if (msg.match(IMAGE_REGEX)) {
    let texture;
    try {
      texture = await PIXI.Assets.load(msg);
    } catch (err) {
      console.error(err);
      return;
    }

    if (texture instanceof AnimatedGIF) {
      // GIFs are loaded as AnimatedGIF which extends Sprite.
      content = texture;
    } else {
      content = PIXI.Sprite.from(texture);
    }

    if (content.height > imageHeight) {
      const scale = imageHeight / content.height;
      content.scale.set(scale);
    }
  } else {
    content = new PIXI.Text(msg, {
      fill: 0xdddddd,
      fontSize: 32,
      wordWrap: true,
      wordWrapWidth: textWidth,
    });

    // Oversize, then scale down to maintain sharpness.
    content.scale.set(0.5);
  }

  content.x = textPadding;
  content.y = textPadding;

  const padding = 2 * textPadding;
  const caretY = content.height + padding + caretHeight;
  const bubbleWidth = Math.max(3 * caretHeight, content.width + padding);

  // Draw chat bubble one corner at a time clock-wise.
  const bubble = new Graphics();
  bubble.beginFill(0xffffff, 0.1);
  bubble.moveTo(0, 0);
  bubble.lineTo(bubbleWidth, 0);
  bubble.lineTo(bubbleWidth, content.height + padding);
  bubble.lineTo(caretX, content.height + padding);
  bubble.lineTo(caretX, caretY);
  bubble.lineTo(caretX - caretHeight, content.height + padding);
  bubble.lineTo(0, content.height + padding);
  bubble.lineTo(0, 0);

  const container = new PIXI.Container();
  container.addChild(bubble);
  container.addChild(content);

  container.x = -caretX;
  container.y = -caretY;

  container.x -= USER_RADIUS;
  container.y -= USER_RADIUS;

  return container;
};
