import { PIXELS_PER_UNIT } from './config';
import { getGroup, getWhisperChains } from './cache/helpers';
import * as sentry from './sentry';

export const get = ({ self, users, groups }, otherId, ...modifiers) => {
  const selfId = self && self.id;
  const selfUser = users.find((u) => u.id === selfId);
  if (!selfId || !selfUser) {
    return 0;
  }

  const chains = getWhisperChains(users);
  const selfChain = chains.find((c) => c.includes(selfId));
  const selfGroup = getGroup(selfId, users, groups);

  const other = users.find((u) => u.id === otherId);
  const otherGroup = getGroup(otherId, users, groups);
  if (!other) {
    return 0;
  }

  const otherChain = chains.find((c) => c.includes(otherId));

  const ctx = {
    selfId,
    selfUser,
    selfGroup,
    selfChain,
    other,
    otherId,
    otherGroup,
    otherChain,
  };

  ctx.sameChain = sameChain(ctx);
  ctx.sameGroup = sameGroup(ctx);

  let volume = 1;
  for (const modifier of modifiers) {
    const v = modifier.func(volume, ctx);
    if (typeof v !== 'number' || !isFinite(v)) {
      sentry.reportError(new Error(`Invalid volume for modifier, ${modifier.name}: ${v}`));
      continue;
    }

    volume = v;
  }

  return volume;
};

export const proximity = (distances, proximityEnabled) => {
  return {
    name: 'proximity',
    func: (volume, ctx) => {
      if (!distances[ctx.otherId] || !proximityEnabled) {
        return volume;
      }

      if (ctx.sameChain) {
        return volume;
      }

      if (ctx.sameGroup) {
        return volume;
      }

      const units = distances[ctx.otherId] / PIXELS_PER_UNIT;
      const squared = units * units;
      return volume * Math.min(1, 1 / squared);
    },
  };
};

export const user = (userVolumes) => {
  return {
    name: 'user',
    func: (volume, ctx) => {
      if (userVolumes[ctx.otherId]) {
        return volume * userVolumes[ctx.otherId];
      }

      return volume * 0.5;
    },
  };
};

export const screen = (screenVolumes) => {
  return {
    name: 'screen',
    func: (volume, ctx) => {
      if (screenVolumes[ctx.otherId]) {
        return volume * screenVolumes[ctx.otherId];
      }

      return 0;
    },
  };
};

export const group = (groupVolumes) => {
  return {
    name: 'group',
    func: (volume, ctx) => {
      if (!ctx.otherGroup) {
        return volume;
      }

      if (ctx.sameChain) {
        return volume;
      }

      const groupVolume = groupVolumes[ctx.otherGroup.id];
      if (!groupVolume) {
        return volume;
      }

      return volume * groupVolume;
    },
  };
};

export const whisper = (whisperVolume) => {
  return {
    name: 'whisper',
    func: (volume, ctx) => {
      if (isQuiet(ctx)) {
        return volume * whisperVolume;
      }

      return volume;
    },
  };
};

export const isolate = () => {
  return {
    name: 'isolate',
    func: (volume, ctx) => {
      if (ctx.sameChain) {
        return volume;
      }

      if (isIsolated(ctx)) {
        return 0;
      }

      return volume;
    },
  };
};

export const overall = (overallVolume) => {
  return {
    name: 'overall',
    func: (volume) => {
      return volume * overallVolume;
    },
  };
};

export const enabled = (soundEnabled) => {
  return {
    name: 'enabled',
    func: (volume) => {
      if (soundEnabled) {
        return volume;
      }

      return 0;
    },
  };
};

function sameChain(ctx) {
  if (ctx.selfChain && ctx.selfChain.includes(ctx.otherId)) {
    return true;
  }

  return false;
}

function sameGroup(ctx) {
  if (ctx.selfGroup && ctx.otherGroup && ctx.selfGroup.id === ctx.otherGroup.id) {
    return true;
  }

  return false;
}

function isQuiet(ctx) {
  if (ctx.selfChain && ctx.selfChain.includes(ctx.otherId)) {
    return false;
  }

  if (ctx.selfChain || ctx.otherChain) {
    return true;
  }

  return false;
}

function isIsolated(ctx) {
  if (ctx.selfChain && ctx.selfChain.includes(ctx.otherId)) {
    return false;
  }

  // Local user's group is isolated.
  if (ctx.selfGroup && ctx.selfGroup.isIsolated && ctx.selfGroup.id !== ctx.other.groupId) {
    return true;
  }

  // Peer's group is isolated.
  if (ctx.otherGroup && ctx.otherGroup.isIsolated && ctx.otherGroup.id !== ctx.selfUser.groupId) {
    return true;
  }
}
