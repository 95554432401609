import { post } from '../client';

export const initialState = {
  data: null,
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE_ROOM':
      return {
        ...state,
        data: {
          ...(state.data || {}),
          ...payload.room,
        },
      };
    case 'DELETE_ROOM':
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export const actions = {
  async join(dispatch, id) {
    const room = await post(`/rooms/${id}/join`);
    dispatch({
      type: 'UPDATE_ROOM',
      payload: { room },
    });
  },
  update(dispatch, room) {
    dispatch({
      type: 'UPDATE_ROOM',
      payload: { room },
    });
  },
  remove(dispatch) {
    dispatch({ type: 'DELETE_ROOM' });
  },
};
