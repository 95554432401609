import { useEffect, useState } from 'react';
import useCache from '../../../../lib/cache/context';
import Modal from '../../../../components/modal/Modal';
import actions from '../../../../lib/cache/actions';
import './KeyBindings.css';

const { uIOhook, UiohookKey } =
  typeof window.require === 'function' && window.require('uiohook-napi');

const defaults = [
  {
    displayName: 'Mute',
    name: 'mute',
    keys: '56,50',
  },
  {
    displayName: 'Unmute',
    name: 'unmute',
    keys: '56,22',
  },
  {
    displayName: 'Whisper group',
    name: 'whisperGroup',
    keys: '29,34',
  },
  {
    displayName: 'Move up',
    name: 'moveUp',
    keys: '29,57416',
  },
  {
    displayName: 'Move down',
    name: 'moveDown',
    keys: '29,57424',
  },
  {
    displayName: 'Move left',
    name: 'moveLeft',
    keys: '29,57419',
  },
  {
    displayName: 'Move right',
    name: 'moveRight',
    keys: '29,57421',
  },
];

function KeyBindings({ setIsOpen }) {
  const { state, dispatch } = useCache();
  const [name, setName] = useState('');

  useEffect(() => {
    if (!name || !uIOhook) {
      return;
    }

    uIOhook.removeAllListeners('keydown');
    uIOhook.removeAllListeners('keyup');
    uIOhook.removeAllListeners('mousedown');
    uIOhook.removeAllListeners('mouseup');

    const pressed = new Set();

    const onDown = (event) => {
      const key = event.keycode || `Mouse${event.button}`;
      pressed.add(key);
    };

    const onUp = async (event) => {
      const key = event.keycode || `Mouse${event.button}`;
      if (!pressed.has(key)) {
        return;
      }

      uIOhook.removeAllListeners('keydown');
      uIOhook.removeAllListeners('keyup');

      await actions.keyBindings.upsert(dispatch, {
        enabled: true,
        name,
        keys: Array.from(pressed).join(','),
      });

      setName('');
    };

    uIOhook.on('mousedown', onDown);
    uIOhook.on('mouseup', onUp);
    uIOhook.on('keydown', onDown);
    uIOhook.on('keyup', onUp);
    uIOhook.start();
  }, [name, dispatch]);

  const toggle = ({ enabled, name, keys }) => {
    return async () => {
      await actions.keyBindings.upsert(dispatch, { enabled: !enabled, name, keys });
    };
  };

  const formatKeys = (keys) => {
    const getKeyName = (key) => {
      if (typeof key === 'string' && key.startsWith('Mouse')) {
        return key;
      }

      const name = Object.keys(UiohookKey).find((k) => UiohookKey[k] === Number(key));
      if (name) {
        return name.toString();
      }

      return 'Unknown';
    };

    const names = keys.split(',').map((k) => getKeyName(k));
    return names.join(' + ');
  };

  const keyBindings = [...defaults];
  for (const keyBinding of keyBindings) {
    const userBinding = state.keyBindings.data.find((kb) => kb.name === keyBinding.name);
    if (userBinding) {
      keyBinding.enabled = userBinding.enabled;
      keyBinding.keys = userBinding.keys;
    }
  }

  return (
    <Modal
      name="key-bindings"
      title="Key Bindings"
      close={() => setIsOpen(false)}
      description="Set global key bindings that can even be used while another window is in focus."
    >
      {!uIOhook && (
        <div>
          <div>Cannot use key bindings in the browser. Download the app instead</div>
          <br />
          <a
            className="btn-primary"
            href="https://github.com/Rydez/voximity-releases/releases/download/v0.1.0/Voximity-Setup-0.1.0.exe"
            rel="noreferrer"
            target="_blank"
          >
            <i className="icon icon-windows icon-dark" /> Windows
          </a>
        </div>
      )}
      {!!uIOhook &&
        keyBindings.map((keyBinding) => (
          <div
            key={keyBinding.name}
            className={`KeyBindings-item ${keyBinding.enabled ? '' : 'KeyBindings-disabled'}`}
          >
            <label className="KeyBindings-name" htmlFor={`${keyBinding.name}-checkbox`}>
              <input
                id={`${keyBinding.name}-checkbox`}
                className="KeyBindings-enabled"
                type="checkbox"
                checked={keyBinding.enabled || false}
                onChange={toggle(keyBinding)}
              />
              <span>{keyBinding.displayName}</span>
            </label>
            <div className="KeyBindings-controls">
              <button
                disabled={!keyBinding.enabled}
                className="btn"
                onClick={() => setName(keyBinding.name)}
              >
                {name === keyBinding.name ? '...' : formatKeys(keyBinding.keys)}
              </button>
            </div>
          </div>
        ))}
    </Modal>
  );
}

export default KeyBindings;
