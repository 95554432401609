import * as self from './self';
import * as users from './users';
import * as room from './room';
import * as rooms from './rooms';
import * as groups from './groups';
import * as roomsUsers from './rooms-users';
import * as messages from './messages';
import * as keyBindings from './key-bindings';

const actions = {
  self: self.actions,
  users: users.actions,
  room: room.actions,
  rooms: rooms.actions,
  groups: groups.actions,
  roomsUsers: roomsUsers.actions,
  messages: messages.actions,
  keyBindings: keyBindings.actions,
};

export default actions;
