import { useState, useEffect, useRef } from 'react';
import useCache from '../../../lib/cache/context';
import actions from '../../../lib/cache/actions';
import Modal from '../../../components/modal/Modal';
import './UploadAvatar.css';

function UploadAvatar({ avatar, setAvatar }) {
  const { dispatch } = useCache();

  const preview = useRef();
  const [source, setSource] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [length, setLength] = useState(0);

  useEffect(() => {
    if (preview.current)
      preview.current.onload = function () {
        const length =
          preview.current.width > preview.current.height
            ? preview.current.height
            : preview.current.width;
        setLength(length);
      };
  }, []);

  useEffect(() => {
    const reader = new FileReader();
    reader.onload = function (event) {
      setSource(event.target.result);
    };

    reader.readAsDataURL(avatar);
  }, [avatar]);

  const onSave = async () => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('file', avatar);

    try {
      await actions.self.uploadAvatar(dispatch, formData);
      setAvatar(null);
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
      setError('Could not save avatar');
    }
  };

  return (
    <Modal name="upload-avatar" title="New Avatar" close={() => setAvatar(null)}>
      <div className="UploadAvatar-preview-container">
        <div
          className="UploadAvatar-region"
          style={{ width: `${length}px`, height: `${length}px` }}
        />
        <img ref={preview} className="UploadAvatar-preview" src={source} alt="avatar preview" />
      </div>
      {!!error && <div className="error">{error}</div>}
      <button disabled={isSubmitting} className="btn-primary" type="submit" onClick={onSave}>
        Save
      </button>
    </Modal>
  );
}

export default UploadAvatar;
