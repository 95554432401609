import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { post } from '../../lib/client';
import useCache from '../../lib/cache/context';
import actions from '../../lib/cache/actions';
import * as socket from '../../lib/socket/socket';

import './Inactive.css';

function Inactive() {
  const location = useLocation();
  const { state, dispatch } = useCache();

  useEffect(() => {
    (async () => {
      await socket.connect(dispatch);
    })();
  }, [dispatch]);

  useEffect(() => {
    const inviteToken = location.state?.inviteToken;
    if (inviteToken) {
      (async () => {
        try {
          const { user, room, roomUsers } = await post(`/invite-token/${inviteToken}`);
          if (room && roomUsers) {
            actions.self.update(dispatch, user);
            actions.rooms.update(dispatch, room);
            actions.roomsUsers.add(dispatch, roomUsers);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }
  }, [location, dispatch]);

  const onLogout = async () => {
    await actions.self.logout(dispatch);
  };

  return (
    <div className="Inactive">
      <div className="Inactive-column">
        <div className="Inactive-header">
          <h1 className="Inactive-title">Voximity</h1>
          <div className="Inactive-account">
            <label
              className="Inactive-account-avatar"
              style={{ backgroundColor: '#' + state.self.data.avatar }}
              htmlFor="avatar"
            >
              <i className="icon-unicorn" />
            </label>

            <label className="Inactive-account-name" htmlFor="avatar">
              {state.self.data.name}
            </label>
            <button className="btn" onClick={onLogout}>
              Logout
            </button>
          </div>
        </div>
        <div className="Inactive-main">
          <h2>Beta access pending...</h2>
          <p>
            Your account has not been activated for the private beta yet. We will email you at{' '}
            <b>{state.self.data.email}</b> when your account has been activated. Don't forget to
            check your spam :)
          </p>
          <p>
            Once your account has been activated, you can invite anyone to join and their account
            will be activated right when they sign up.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Inactive;
