import * as groups from './groups';
import * as self from './self';
import * as users from './users';
import * as roomsUsers from './rooms-users';
import * as rooms from './rooms';
import * as room from './room';
import * as messages from './messages';
import { DEV_HOST } from '../config';

let URL = `ws://${DEV_HOST}:8080`;
if (process.env.NODE_ENV === 'production') {
  URL = 'wss://api.voximity.chat';
}

let ws = null;
const handlers = {
  ...groups.handlers,
  ...self.handlers,
  ...users.handlers,
  ...roomsUsers.handlers,
  ...rooms.handlers,
  ...room.handlers,
  ...messages.handlers,
};

let disconnecting = false;

export const connect = async (dispatch) => {
  disconnecting = false;

  if (typeof window === 'undefined') {
    return;
  }

  const handleError = (error) => {
    console.log('websocket error:', error);
  };

  const handleClose = () => {
    console.log('websocket connection closed');
    if (disconnecting) {
      return;
    }

    setTimeout(() => connect(dispatch), 1000);
  };

  const handleMessage = (message) => {
    const msg = JSON.parse(message.data);
    const handler = handlers[msg.type];
    if (!handler) {
      console.log('websocket event without a handler');
      return;
    }

    handler(dispatch, msg.data);
  };

  await new Promise((resolve) => {
    ws = new WebSocket(URL);
    ws.addEventListener('error', handleError);
    ws.addEventListener('close', handleClose);
    ws.addEventListener('message', handleMessage);
    ws.addEventListener('open', () => {
      console.log('websocket connection opened');
      resolve();
    });
  });
};

export const disconnect = () => {
  disconnecting = true;
};

export const add = (name, handler) => {
  handlers[name] = handler;
};

export const remove = (name) => {
  delete handlers[name];
};

export const send = (message) => {
  if (ws) {
    ws.send(JSON.stringify(message));
  }
};
