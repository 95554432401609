import { useState } from 'react';
import useCache from '../../../lib/cache/context';
import actions from '../../../lib/cache/actions';
import Modal from '../../../components/modal/Modal';
import './RemoveRoom.css';

function RemoveRoom({ removeRoom, setRemoveRoom }) {
  const { dispatch } = useCache();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onConfirm = async () => {
    setIsSubmitting(true);
    await actions.rooms.remove(dispatch, removeRoom.id);
    setRemoveRoom(null);
  };

  return (
    <Modal name="remove-room" title="Remove Room" close={() => setRemoveRoom(null)}>
      <div>Are you sure you want to remove {removeRoom.name}? You can always be invited back.</div>
      <br />
      <button disabled={isSubmitting} className="btn-primary" onClick={onConfirm}>
        Remove
      </button>
    </Modal>
  );
}

export default RemoveRoom;
