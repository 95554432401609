import { createContext, useReducer, useContext, useMemo } from 'react';
import reducer, { initialState } from './reducer';

const CacheContext = createContext(initialState);

export const CacheProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const cache = useMemo(() => ({ state, dispatch }), [state]);
  return <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>;
};

const useCache = () => {
  const context = useContext(CacheContext);
  if (context === undefined) {
    throw new Error('useCache must be used within CacheContext');
  }

  return context;
};

export default useCache;
