import { pixiState } from './state';

let panning = false;

export const pan = () => {
  let xLast = null;
  let yLast = null;

  pixiState.app.stage.on('pointerdown', (event) => {
    if (event.target.label !== 'stage') {
      return;
    }

    if (panning) {
      return;
    }

    xLast = event.pageX;
    yLast = event.pageY;
    panning = true;
    window.document.body.classList.add('grabbing');
    if (pixiState.self && pixiState.self.positioner) {
      pixiState.self.positioner.show = true;
    }
  });

  const release = () => {
    if (!panning) {
      return;
    }

    panning = false;
    window.document.body.classList.remove('grab');
    window.document.body.classList.remove('grabbing');
    if (pixiState.self && pixiState.self.positioner) {
      pixiState.self.positioner.show = false;
    }
  };

  pixiState.app.stage.on('pointerup', release);
  pixiState.app.stage.on('pointerupoutside', release);

  const onPointerMove = (event) => {
    if (pixiState.isPinching) {
      return;
    }

    if (!panning) {
      xLast = event.pageX;
      yLast = event.pageY;
      return;
    }

    const x = event.pageX;
    const y = event.pageY;

    pixiState.room.x += x - xLast;
    pixiState.room.y += y - yLast;

    xLast = x;
    yLast = y;
  };

  window.addEventListener('pointermove', onPointerMove);
  return () => window.removeEventListener('pointermove', onPointerMove);
};

export const zoom = () => {
  let xMouse = 0;
  let yMouse = 0;

  pixiState.app.stage.on('pointermove', (event) => {
    xMouse = event.data.global.x;
    yMouse = event.data.global.y;
  });

  let interval = null;
  pixiState.app.view.addEventListener('wheel', (event) => {
    if (event.deltaY === 0) {
      return;
    }

    if (pixiState.self && pixiState.self.positioner) {
      pixiState.self.positioner.show = true;
      clearInterval(interval);
      interval = setTimeout(() => {
        if (!panning && !pixiState.dragging) {
          pixiState.self.positioner.show = false;
        }
      }, 100);
    }

    let factor = 0.95;
    if (event.deltaY < 0) {
      factor = 1 / factor;
    }

    pixiState.room.scale.x *= factor;
    pixiState.room.scale.y *= factor;

    // Limit amount of zoom.
    if (pixiState.room.scale.x < 0.25) {
      pixiState.room.scale.x = 0.25;
      pixiState.room.scale.y = 0.25;
    } else if (pixiState.room.scale.x > 2) {
      pixiState.room.scale.x = 2;
      pixiState.room.scale.y = 2;
    } else {
      // Zoom to cursor position. Don't pan if we're at min or max zoom
      const dx = (xMouse - pixiState.room.x) * (factor - 1);
      const dy = (yMouse - pixiState.room.y) * (factor - 1);
      pixiState.room.x -= dx;
      pixiState.room.y -= dy;
    }
  });
};

export const pinch = () => {
  let xOneLast = 0;
  let yOneLast = 0;
  let xTwoLast = 0;
  let yTwoLast = 0;

  pixiState.isPinching = false;

  pixiState.app.view.addEventListener('touchend', () => {
    pixiState.isPinching = false;
    if (pixiState.self && pixiState.self.positioner) {
      pixiState.self.positioner.show = false;
    }
  });

  pixiState.app.view.addEventListener('touchstart', (event) => {
    if (event.touches.length === 2) {
      xOneLast = event.touches[0].pageX;
      yOneLast = event.touches[0].pageY;
      xTwoLast = event.touches[1].pageX;
      yTwoLast = event.touches[1].pageY;

      pixiState.isPinching = true;
      if (pixiState.self && pixiState.self.positioner) {
        pixiState.self.positioner.show = true;
      }
    }
  });

  pixiState.app.view.addEventListener('touchmove', (event) => {
    if (!pixiState.isPinching) {
      return;
    }

    const lastDistance = Math.hypot(xOneLast - xTwoLast, yOneLast - yTwoLast);

    const xOne = event.touches[0].pageX;
    const yOne = event.touches[0].pageY;
    const xTwo = event.touches[1].pageX;
    const yTwo = event.touches[1].pageY;
    const distance = Math.hypot(xOne - xTwo, yOne - yTwo);

    let factor = 0.95;
    if (distance - lastDistance > 0) {
      factor = 1 / factor;
    }

    const xMin = xOne < xTwo ? xOne : xTwo;
    const xMax = xOne > xTwo ? xOne : xTwo;
    const yMin = yOne < yTwo ? yOne : yTwo;
    const yMax = yOne > yTwo ? yOne : yTwo;
    const xMidpoint = xMin + (xMax - xMin) / 2;
    const yMidpoint = yMin + (yMax - yMin) / 2;

    pixiState.room.scale.x *= factor;
    pixiState.room.scale.y *= factor;

    // Zoom to cursor position.
    const dx = (xMidpoint - pixiState.room.x) * (factor - 1);
    const dy = (yMidpoint - pixiState.room.y) * (factor - 1);
    pixiState.room.x -= dx;
    pixiState.room.y -= dy;

    xOneLast = xOne;
    yOneLast = yOne;
    xTwoLast = xTwo;
    yTwoLast = yTwo;
  });
};
