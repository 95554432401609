import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import { pixiState } from './state';
import { USER_RADIUS } from '../config';

export const disableOtherScreen = (userId) => {
  const other = pixiState.others[userId];
  if (other) {
    disableScreen(other);
  }
};

export const disableSelfScreen = () => {
  disableScreen(pixiState.self);
};

export const enableOtherScreen = async (userId, ref, height) => {
  const other = pixiState.others[userId];
  if (other) {
    await enableScreen(other, ref, height);
  }
};

export const enableSelfScreen = async (ref, height) => {
  await enableScreen(pixiState.self, ref, height);
};

const disableScreen = (display) => {
  if (!display || !display.screen) {
    return;
  }

  display.circle.visible = true;
  display.screen.destroy(true);
  delete display.screen;
};

const enableScreen = async (display, ref, height) => {
  if (display.screen) {
    display.screen.destroy(true);
    delete display.screen;
  }

  const source = new PIXI.VideoResource(ref.current);
  source.updateFPS = 30;
  const texture = PIXI.Texture.from(source);
  const sprite = new PIXI.Sprite(texture);
  sprite.anchor.set(0.5);
  sprite.scale.set((2 * USER_RADIUS) / height);

  const circle = new Graphics();
  circle.beginFill(0x000000, 1);
  circle.drawCircle(0, 0, USER_RADIUS);

  const container = new PIXI.Container();
  container.zIndex = 1000;
  container.addChild(circle);
  container.addChild(sprite);
  container.mask = circle;
  container.eventMode = 'static';

  display.paralyzed.addChild(container);
  display.screen = container;

  display.circle.visible = false;
};
