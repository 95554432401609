import { useRef, useEffect } from 'react';
import { enableOtherVideo, disableOtherVideo } from '../../../lib/canvas/videos';

function Participant({ participant }) {
  const userId = participant.customParticipantId;
  const videoRef = useRef(null);

  useEffect(() => {
    if (!participant.videoEnabled || !participant.videoTrack) {
      disableOtherVideo(userId);
      videoRef.current.srcObject = null;
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(participant.videoTrack);
    videoRef.current.srcObject = stream;

    const enable = () => {
      enableOtherVideo(userId, videoRef, videoRef.current.videoHeight);
    };

    const videoRefCopy = videoRef.current;
    videoRefCopy.addEventListener('loadedmetadata', enable, { once: true });
    videoRefCopy.addEventListener('resize', enable);
    return () => {
      disableOtherVideo(userId);
      if (videoRefCopy) {
        videoRefCopy.removeEventListener('resize', enable);
      }
    };
  }, [userId, participant.videoEnabled, participant.videoTrack]);

  return (
    <div>
      {/* This muted element must exist to work around a chrome bug. The source is set above. */}
      <video style={{ display: 'none' }} ref={videoRef} autoPlay playsInline muted />
    </div>
  );
}

export default Participant;
