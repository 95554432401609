import { DEV_HOST } from './config';

const PROD_URL = 'https://api.voximity.chat';
const DEV_URL = `http://${DEV_HOST}:8080`;
const URL = process.env.NODE_ENV === 'production' ? PROD_URL : DEV_URL;

export const get = async (path) => {
  return exec('GET', path);
};

export const post = async (path, data) => {
  return exec('POST', path, data);
};

export const del = async (path) => {
  return exec('DELETE', path);
};

const exec = async (method, path, data) => {
  const options = {
    method,
    mode: 'cors',
    credentials: 'include',
    headers: {},
  };

  if (data instanceof FormData) {
    // Don't set Content-Type, it's set automatically.
    options.body = data;
  } else if (data) {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(URL + path, options);
    if (response.status === 204) {
      return null;
    }

    const json = await response.json();
    if (!response.ok) {
      console.log(response);
      throw new HTTPError(response.status, json.message);
    }

    return json;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

class HTTPError extends Error {
  constructor(code, message) {
    super(message);
    this.status = code;
  }
}
