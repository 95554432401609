import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import actions from '../cache/actions';
import * as socket from '../socket/socket';
import { getGroup, getWhisperChains } from '../cache/helpers';
import { pixiState } from './state';

export const updateWhispers = (selfId, users, groups) => {
  updateUserDisplays(selfId, users, groups);
  updateLines(users);
};

let container = new PIXI.Container();
const updateLines = (users) => {
  pixiState.room.removeChild(container);
  container.destroy(true);
  container = new PIXI.Container();
  pixiState.room.addChild(container);

  for (const user of users) {
    if (user.whisperUserId) {
      const otherUser = users.find((u) => u.id === user.whisperUserId);
      createLine(user, otherUser);
    }

    if (user.whisperGroupId) {
      const otherUsers = users.filter((u) => u.groupId === user.whisperGroupId);
      for (const otherUser of otherUsers) {
        createLine(user, otherUser);
      }
    }
  }
};

const createLine = (user1, user2) => {
  const line = new Graphics();
  line.lineStyle(2, 0x4dd7b7, 0.8);
  line.moveTo(user1.x, user1.y);
  line.lineTo(user2.x, user2.y);
  container.addChild(line);

  const anchor1 = new Graphics();
  anchor1.beginFill(0x4dd7b7, 1);
  anchor1.drawCircle(user1.x, user1.y, 5);
  container.addChild(anchor1);

  const anchor2 = new Graphics();
  anchor2.beginFill(0x4dd7b7, 1);
  anchor2.drawCircle(user2.x, user2.y, 5);
  container.addChild(anchor2);
};

const updateUserDisplays = (selfId, users, groups) => {
  const chains = getWhisperChains(users);
  const selfChain = chains.find((c) => c.includes(selfId));
  const selfGroup = getGroup(selfId, users, groups);
  const self = users.find((u) => u.id === selfId);

  for (const user of users) {
    const other = pixiState.others[user.id];
    if (!other) {
      continue;
    }

    reset(other);

    if (selfChain && selfChain.includes(user.id)) {
      light(other);
      continue;
    }

    if (selfGroup && selfGroup.isIsolated && selfGroup.id !== user.groupId) {
      isolate(other);
      continue;
    }

    const userGroup = getGroup(user.id, users, groups);
    if (userGroup && userGroup.isIsolated && userGroup.id !== self.groupId) {
      isolate(other);
      continue;
    }

    if (selfChain) {
      dim(other);
      continue;
    }

    const chain = chains.find((c) => c.includes(user.id));
    if (chain) {
      dim(other);
      continue;
    }
  }
};

const reset = (userDisplay) => {
  userDisplay.container.alpha = 1;
  userDisplay.circle.scale.set(1);
  userDisplay.circle.alpha = 1;
  userDisplay.indicator.visible = true;
  if (userDisplay.screen) {
    userDisplay.screen.visible = true;
    userDisplay.screen.alpha = 1;
    userDisplay.screen.scale.set(1);
  }
};

const dim = (userDisplay) => {
  userDisplay.container.alpha = 0.5;
  userDisplay.circle.scale.set(0.8);
  userDisplay.circle.alpha = 0.5;
  if (userDisplay.screen) {
    userDisplay.screen.visible = true;
    userDisplay.screen.alpha = 0.5;
    userDisplay.screen.scale.set(0.8);
  }
};

const light = (userDisplay) => {
  userDisplay.circle.scale.set(1.1);
  userDisplay.circle.alpha = 1;
  if (userDisplay.screen) {
    userDisplay.screen.visible = true;
    userDisplay.screen.alpha = 1;
    userDisplay.screen.scale.set(1.1);
  }
};

const isolate = (userDisplay) => {
  userDisplay.circle.scale.set(0.8);
  userDisplay.circle.alpha = 0.5;
  userDisplay.indicator.visible = false;
  if (userDisplay.screen) {
    userDisplay.circle.visible = true;
    userDisplay.screen.visible = false;
  }
};

export const whisperTo = (selfId, userId, dispatch) => {
  return (event) => {
    if (event.data.originalEvent.button !== 0) {
      return;
    }

    const endWhisper = () => {
      socket.send({
        type: 'whisperUser:end',
        data: { whisperUserId: null },
      });

      actions.users.update(dispatch, { id: selfId, whisperUserId: null });

      pixiState.app.stage.off('pointerup', endWhisper);
      pixiState.app.stage.off('pointerupoutside', endWhisper);
    };

    pixiState.app.stage.once('pointerup', endWhisper);
    pixiState.app.stage.once('pointerupoutside', endWhisper);

    socket.send({
      type: 'whisperUser:start',
      data: { whisperUserId: userId },
    });

    actions.users.update(dispatch, { id: selfId, whisperUserId: userId });
  };
};
