import { useDyteSelector } from '@dytesdk/react-web-core';

function Camera({ className }) {
  const self = useDyteSelector((m) => m.self);

  const toggleCamera = async () => {
    if (self.videoEnabled) {
      await self.disableVideo();
    } else {
      await self.enableVideo();
    }
  };

  return (
    <button disabled={!self.roomJoined} className={`btn ${className}`} onClick={toggleCamera}>
      {self.videoEnabled ? (
        <>
          <i className="icon icon-no-camera" />
          Stop
        </>
      ) : (
        <>
          <i className="icon icon-camera" /> Camera
        </>
      )}
    </button>
  );
}

export default Camera;
