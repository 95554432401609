import * as PIXI from 'pixi.js';
import { SmoothGraphics as Graphics } from '@pixi/graphics-smooth';
import * as socket from '../socket/socket';
import { USER_RADIUS } from '../config';
import actions from '../cache/actions';
import { pixiState } from './state';
// import { paralyzeTransformInheritance } from './paralyze';
import { drawPositioner } from './positioner';
import { drawIndicator } from './indicator';
import { updateTextPositions } from './text';

// Because this is an async function called from a useEffect, it could run twice.
// Use a lock to prevent the user from being created more than once.
let lock = false;

export const initializeUser = async (userSelf, dispatch) => {
  if (!pixiState.app) {
    return;
  }

  if (pixiState.self) {
    return;
  }

  if (lock) {
    return;
  }

  lock = true;
  try {
    const self = await drawUser(userSelf);
    pixiState.self = self;

    pixiState.self.positioner = drawPositioner();
    pixiState.self.container.addChildAt(pixiState.self.positioner, 0);

    self.container.zIndex = 10;

    handleDragging(userSelf, dispatch);

    pixiState.app.ticker.add(move);
    pixiState.room.addChild(self.container);
  } finally {
    lock = false;
  }
};

const movements = new Set();
const move = (time) => {
  if (!movements.size) {
    return;
  }

  if (movements.has('up')) {
    pixiState.self.container.y -= time * 5;
  }

  if (movements.has('down')) {
    pixiState.self.container.y += time * 5;
  }

  if (movements.has('left')) {
    pixiState.self.container.x -= time * 5;
  }

  if (movements.has('right')) {
    pixiState.self.container.x += time * 5;
  }
};

let moving = false;
export const updateMovement = (selfId, activeKeyBindings, dispatch) => {
  if (!pixiState.self) {
    return;
  }

  if (activeKeyBindings.includes('moveUp')) {
    movements.add('up');
  } else {
    movements.delete('up');
  }

  if (activeKeyBindings.includes('moveDown')) {
    movements.add('down');
  } else {
    movements.delete('down');
  }

  if (activeKeyBindings.includes('moveLeft')) {
    movements.add('left');
  } else {
    movements.delete('left');
  }

  if (activeKeyBindings.includes('moveRight')) {
    movements.add('right');
  } else {
    movements.delete('right');
  }

  if (movements.size) {
    moving = true;
    pixiState.self.positioner.show = true;
  } else if (moving) {
    moving = false;
    pixiState.self.positioner.show = false;

    socket.send({
      type: 'move:end',
      data: { x: pixiState.self.container.x, y: pixiState.self.container.y },
    });

    actions.users.update(dispatch, {
      id: selfId,
      x: pixiState.self.container.x,
      y: pixiState.self.container.y,
    });
  }
};

const handleDragging = (userSelf, dispatch) => {
  pixiState.dragging = false;
  pixiState.self.container.on('pointerenter', (event) => {
    // Avoid mouse events from pixi app triggering through
    // other HTML elements.
    if (event.nativeEvent.target.localName !== 'canvas') {
      return;
    }

    window.document.body.classList.add('grab');
  });

  pixiState.self.container.on('pointerleave', () => {
    if (!pixiState.dragging) {
      window.document.body.classList.remove('grab');
      window.document.body.classList.remove('grabbing');
    }
  });

  const onDragMove = (event) => {
    if (pixiState.dragging && !pixiState.isPinching) {
      pixiState.self.container.parent.toLocal(
        event.global,
        null,
        pixiState.self.container.position
      );

      pixiState.self.container.x = Math.round(pixiState.self.container.x);
      pixiState.self.container.y = Math.round(pixiState.self.container.y);
    }
  };

  const onDragStart = (event) => {
    if (!pixiState.dragging) {
      pixiState.dragging = true;
      window.document.body.classList.remove('grab');
      window.document.body.classList.add('grabbing');
      pixiState.app.stage.on('pointermove', onDragMove);

      pixiState.self.positioner.show = true;
    }
  };

  const onDragEnd = (event) => {
    if (pixiState.dragging) {
      pixiState.dragging = false;
      window.document.body.classList.remove('grabbing');
      window.document.body.classList.remove('grab');
      pixiState.app.stage.off('pointermove', onDragMove);

      pixiState.self.positioner.show = false;

      socket.send({
        type: 'move:end',
        data: { x: pixiState.self.container.x, y: pixiState.self.container.y },
      });

      actions.users.update(dispatch, {
        id: userSelf.id,
        x: pixiState.self.container.x,
        y: pixiState.self.container.y,
      });
    }
  };

  pixiState.self.container.on('pointerdown', onDragStart);

  pixiState.app.stage.on('pointerup', onDragEnd);
  pixiState.app.stage.on('pointerupoutside', onDragEnd);
};

export const drawUser = async (user) => {
  const container = new PIXI.Container();
  container.eventMode = 'static';

  const circle = new PIXI.Container();
  if (user.avatar.startsWith('http')) {
    const texture = await PIXI.Assets.load(user.avatar);
    const sprite = PIXI.Sprite.from(texture);
    sprite.width = 2 * USER_RADIUS;
    sprite.height = 2 * USER_RADIUS;
    sprite.anchor.set(0.5);

    const mask = new Graphics();
    mask.beginFill(0x000000, 1);
    mask.drawCircle(0, 0, USER_RADIUS);
    circle.mask = mask;

    circle.addChild(mask);
    circle.addChild(sprite);
  } else {
    const unicornWidth = 2 * USER_RADIUS - 30;
    const unicornTexture = PIXI.Texture.from(
      `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="${
        2 * unicornWidth
      }px" height="${
        2 * unicornWidth
      }px"><path d="M22.146,10.948L13,7l0-4c0-0.897-1.103-1.355-1.718-0.703C5.506,8.415,3,22,3,22h12	c0.552,0,1-0.448,1-1v-4h4.25c1.604-0.045,3.212-0.976,3.519-2.511C24.041,13.127,23.388,11.569,22.146,10.948z" opacity=".35"/><circle cx="14" cy="11" r="1"/><path d="M7.596,5.204C6.648,4.73,5.492,5.071,4.969,5.993c-1.44,2.538-3.99,7.565-4.853,14.897C0.046,21.48,0.518,22,1.113,22L3,22	c0,0,1.761-9.538,5.73-16.229L7.596,5.204z"/><path d="M17.081,8.762l2.19-5.949c0.214-0.641-0.636-1.086-1.041-0.546l-3.908,5.304L17.081,8.762z"/></svg>`
    );

    const circleBackground = new Graphics();
    circleBackground.beginFill(Number('0x' + user.avatar), 1);
    circleBackground.drawCircle(0, 0, USER_RADIUS);

    const sprite = PIXI.Sprite.from(unicornTexture);
    sprite.alpha = 0.6;
    sprite.scale.set(0.5);
    sprite.anchor.set(0.5);

    circle.addChild(circleBackground);
    circle.addChild(sprite);
  }

  const name = new PIXI.Text(user.name, {
    fill: 0xdddddd,
    fontSize: 36,
  });

  // Oversize, then scale down to maintain sharpness.
  name.scale.set(0.5);

  const indicator = drawIndicator();

  const paralyzed = new PIXI.Container();
  paralyzed.sortableChildren = true;

  // Uncommenting this will make users remain the same size when zooming.
  // Though, this results in people overlapping eachother.
  // paralyzeTransformInheritance(paralyzed, true, true);

  paralyzed.addChild(indicator);
  paralyzed.addChild(circle);
  paralyzed.addChild(name);

  container.addChild(paralyzed);
  container.x = user.x;
  container.y = user.y;

  const display = { container, indicator, circle, name, paralyzed, avatar: user.avatar };
  updateTextPositions(display);

  return display;
};
