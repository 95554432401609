import { useRef, useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import { enableSelfScreen, disableSelfScreen } from '../../../lib/canvas/screens';
import Participant from './Participant';

function Screens({
  soundEnabled,
  overallVolume,
  screenVolumes,
  groupVolumes,
  distances,
  whisperVolume,
  proximityEnabled,
  pins,
}) {
  const selfVideoRef = useRef(null);
  const self = useDyteSelector((m) => m.self);
  const participants = useDyteSelector((m) => m.participants.joined);

  useEffect(() => {
    if (
      !self.screenShareEnabled ||
      !(self.screenShareTracks.video || self.screenShareTracks.audio)
    ) {
      disableSelfScreen();
      selfVideoRef.current.srcObject = null;
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(self.screenShareTracks.video);
    selfVideoRef.current.srcObject = stream;

    const enable = () => {
      enableSelfScreen(selfVideoRef, selfVideoRef.current.videoHeight);
    };

    const videoRefCopy = selfVideoRef.current;
    videoRefCopy.addEventListener('loadedmetadata', enable);
    return () => {
      if (videoRefCopy) {
        videoRefCopy.removeEventListener('loadedmetadata', enable);
      }
    };
  }, [self.screenShareEnabled, self.screenShareTracks.video, self.screenShareTracks.audio]);

  return (
    <>
      <video style={{ display: 'none' }} ref={selfVideoRef} autoPlay playsInline muted />

      {participants.toArray().map((p) => (
        <Participant
          key={p.id}
          participant={p}
          soundEnabled={soundEnabled}
          overallVolume={overallVolume}
          whisperVolume={whisperVolume}
          proximityEnabled={proximityEnabled}
          screenVolumes={screenVolumes}
          groupVolumes={groupVolumes}
          distances={distances}
          pins={pins}
        />
      ))}
    </>
  );
}

export default Screens;
