import { useState, useEffect } from 'react';
import Modal from '../../components/modal/Modal';
import './Updater.css';

const { ipcRenderer } = typeof window.require === 'function' && window.require('electron');

function Updater({ setIsUpdating }) {
  const [status, setStatus] = useState('Checking for updates...');
  const [showPercent, setShowPercent] = useState(false);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    ipcRenderer.on('updater', (event, data) => {
      if (data.status === 'available') {
        setStatus('Update found...');
        setShowPercent(true);
      } else if (data.status === 'unavailable') {
        setStatus('No updates...');
        setIsUpdating(false);
      } else if (data.status === 'error') {
        setStatus('Error while updating...');
        setIsUpdating(false);
      } else if (data.status === 'progress') {
        setStatus('Downloading...');
        setPercent(data.percent);
      } else if (data.status === 'done') {
        setStatus('Done! Restarting...');
        setShowPercent(false);
      }
    });

    ipcRenderer.invoke('start-updater');

    return () => ipcRenderer.removeAllListeners('updater');
  }, [setIsUpdating]);

  return (
    <Modal name="updater" title="Voximity updates" noClose={true}>
      <div>{status}</div>
      {showPercent && (
        <div className="Updater-progress-bar-container">
          <div className="Updater-progress-bar" style={{ width: `${percent}%` }}></div>
        </div>
      )}
    </Modal>
  );
}

export default Updater;
