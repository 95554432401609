import { useState, useRef, useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import * as vol from '../../../lib/volume';
import useCache from '../../../lib/cache/context';
import { shorttouchDom } from '../../../lib/touch';
import Grid, { maxCols, maxRows } from '../../../components/grid/Grid';
import './Pins.css';

function Pins({ pins, setPins, pinsHidden, soundEnabled, overallVolume, screenVolumes, setPopup }) {
  const { state } = useCache();
  const participants = useDyteSelector((m) => m.participants.joined);

  const videosRef = useRef(null);
  const [gainNodes, setGainNodes] = useState({});

  const [gridItems, setGridItems] = useState([]);

  const [layout, setLayout] = useState([]);

  useEffect(() => {
    const onUpdate = (participant) => {
      if (!participant.screenShareEnabled) {
        setPins((p) => ({
          ...p,
          [participant.customParticipantId]: false,
        }));
      }
    };

    const onLeft = (participant) => {
      setPins((p) => ({
        ...p,
        [participant.customParticipantId]: false,
      }));
    };

    participants.on('participantLeft', onLeft);
    participants.on('screenShareUpdate', onUpdate);
    return () => {
      participants.off('participantLeft', onLeft);
      participants.off('screenShareUpdate', onUpdate);
    };
  }, [participants, setPins]);

  const getVideosRefMap = () => {
    if (!videosRef.current) {
      videosRef.current = new Map();
    }

    return videosRef.current;
  };

  const setVideoRef = (userId) => {
    return (node) => {
      const map = getVideosRefMap();
      if (node) {
        map.set(userId, node);
      } else {
        map.delete(userId);
      }
    };
  };

  useEffect(() => {
    const setupTracks = (userId, node) => {
      const participant = [...participants.values()].find((p) => p.customParticipantId === userId);
      if (!participant || !participant.screenShareEnabled || !participant.screenShareTracks.video) {
        return;
      }

      const stream = new MediaStream();
      stream.addTrack(participant.screenShareTracks.video);
      if (participant.screenShareTracks.audio) {
        stream.addTrack(participant.screenShareTracks.audio);
        const audioContext = new window.AudioContext();
        const source = audioContext.createMediaStreamSource(stream);
        const gain = audioContext.createGain();
        setGainNodes((n) => ({
          ...n,
          [userId]: gain,
        }));

        source.connect(gain).connect(audioContext.destination);
      }

      node.srcObject = stream;
    };

    const map = getVideosRefMap();
    for (const userId of gridItems) {
      const node = map.get(userId);

      // If the gain node already exists, then no need to setup again.
      if (node && !gainNodes[userId]) {
        setupTracks(userId, node);
      }
    }
  }, [gridItems, gainNodes, participants]);

  useEffect(() => {
    // Add new pins to the top/end.
    for (const [userId, isPinned] of Object.entries(pins)) {
      if (!isPinned) {
        continue;
      }

      if (gridItems.includes(userId)) {
        continue;
      }

      const w = 3;
      const h = 3;
      const x = maxCols - w;
      const y = maxRows - h;
      setLayout((l) => [...l, { i: userId, x, y, w, h }]);
      setGridItems((g) => [...g, userId]);
    }

    // Remove unpinned items.
    if (gridItems.find((id) => !pins[id])) {
      setGridItems((g) => g.filter((id) => pins[id]));
      setLayout((l) => l.filter((li) => pins[li.id]));

      const newGainNodes = { ...gainNodes };
      for (const userId of Object.keys(gainNodes)) {
        if (!pins[userId]) {
          delete newGainNodes[userId];
        }
      }

      setGainNodes(newGainNodes);
    }
  }, [pins, gridItems, gainNodes]);

  const afterAdjustment = (newItem) => {
    // Move item that's being adjusted to the top/end.
    if (gridItems[gridItems.length - 1] !== newItem.i) {
      setGridItems((g) => [...g.filter((id) => id !== newItem.i), newItem.i]);
    }
  };

  const onRightClick = (userId) => {
    return (event) => {
      setPopup({
        userId,
        x: event.clientX,
        y: event.clientY,
        settings: ['screen-pin', 'screen-volume'],
      });
    };
  };

  const onTap = (userId) => {
    return (event) => {
      const [touch] = event.touches;
      if (touch) {
        setPopup({
          userId,
          x: touch.clientX,
          y: touch.clientY,
          settings: ['screen-pin', 'screen-volume'],
        });
      }
    };
  };

  useEffect(() => {
    for (const userId of Object.keys(pins)) {
      if (!pins[userId]) {
        continue;
      }

      const volume = vol.get(
        {
          self: state.self.data,
          users: state.users.data,
          groups: state.groups.data,
        },
        userId,
        vol.screen(screenVolumes),
        vol.overall(overallVolume),
        vol.enabled(soundEnabled)
      );

      if (gainNodes[userId]) {
        gainNodes[userId].gain.value = volume;
      }
    }
  }, [
    gainNodes,
    state.self.data,
    state.users.data,
    state.groups.data,
    pins,
    soundEnabled,
    overallVolume,
    screenVolumes,
  ]);

  return (
    <Grid
      layout={layout}
      setLayout={setLayout}
      disableContextMenu={true}
      afterAdjustment={afterAdjustment}
      hidden={pinsHidden}
    >
      {gridItems.map((userId) => (
        <div
          key={userId}
          className="Pins-item"
          onContextMenu={onRightClick(userId)}
          onTouchStart={shorttouchDom(onTap(userId))}
        >
          <video className="Pins-video" ref={setVideoRef(userId)} autoPlay playsInline muted />
        </div>
      ))}
    </Grid>
  );
}

export default Pins;
