import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useCache from '../../lib/cache/context';
import Login from './login/Login';
import Subtitle from './Subtitle';
import Greeting from './greeting/Greeting';
import './Landing.css';
import proximityVideo from './videos/proximity.mp4';
import proximityPoster from './videos/proximity.jpg';
import whisperingVideo from './videos/whispering.mp4';
import whisperingPoster from './videos/whispering.jpg';
import groupsVideo from './videos/groups.mp4';
import groupsPoster from './videos/groups.jpg';
import streamVideo from './videos/stream.mp4';
import streamPoster from './videos/stream.jpg';
import textVideo from './videos/text.mp4';
import textPoster from './videos/text.jpg';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function Landing({ isElectron }) {
  const navigate = useNavigate();
  const { state } = useCache();
  const [form, setForm] = useState('');
  const [inviteToken, setInviteToken] = useState('');
  const [resetPasswordToken, setResetPasswordToken] = useState('');
  const [fakeAudio, setFakeAudio] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetPasswordToken = urlParams.get('resetPasswordToken');
    if (resetPasswordToken) {
      setResetPasswordToken(resetPasswordToken);
      setForm('new-password');
    }

    const invToken = urlParams.get('inviteToken');
    if (invToken) {
      if (state.self.data) {
        window.history.pushState('', '', '/'); // Ensure that the back-button works after redirect.
        navigate('/app', { state: { inviteToken: invToken } });
      } else {
        setForm('signup');
        setInviteToken(invToken);
      }
    }

    const fakeAudioParam = urlParams.get('audio');
    if (fakeAudioParam) {
      setFakeAudio(fakeAudioParam);
    }
  }, [navigate, state.self.data]);

  if (isElectron) {
    return <Login form={form || 'login'} setForm={setForm} isElectron={isElectron} />;
  }

  return (
    <>
      {!!form && (
        <Login
          form={form}
          setForm={setForm}
          inviteToken={inviteToken}
          resetPasswordToken={resetPasswordToken}
          fakeAudio={fakeAudio}
        />
      )}

      <div className="Landing">
        <div className="Landing-background-middle">
          <div className="Landing-background-top">
            <div className="Landing-content">
              <div className="Landing-header">
                <h1 className="Landing-logo">
                  <i className="icon icon-unicorn" />
                  Voximity
                </h1>
                <div className="Landing-auth">
                  {!state.self.data ? (
                    <>
                      <button className="btn-secondary" onClick={() => setForm('login')}>
                        Login
                      </button>
                      <button className="btn-secondary" onClick={() => setForm('signup')}>
                        Signup
                      </button>
                    </>
                  ) : (
                    <Link to="/app" className="btn-secondary">
                      Open Voximity
                    </Link>
                  )}
                </div>
              </div>
              <div className="Landing-hero">
                <div className="Landing-hero-col">
                  <div>
                    <h2 className="Landing-title">
                      Sick of the chaos? Gather everyone, without crosstalk.
                    </h2>
                    <p className="Landing-description">
                      Meet and hang out naturally with Voximity.
                    </p>
                    <Subtitle />
                  </div>
                </div>
                <div className="Landing-hero-col">
                  <Greeting />
                </div>
              </div>
              <div className="Landing-beta">
                <div className="Landing-beta-title">
                  <i className="icon icon-sparkle icon-big" />
                  Sign up today!
                </div>
                <p>
                  <span className="Landing-beta-bold">Get together without the crosstalk.</span>{' '}
                  Single-channel meetings don't work for socializing. More than 1 topic of
                  conversation becomes chaotic. Voximity is the simplest way for everyone to feel
                  together without being overwhelmed.
                </p>
                <button className="btn-primary" onClick={() => setForm('signup')}>
                  <i className="icon icon-sparkle icon-dark icon-big" /> SIGNUP
                </button>
              </div>
              {/* <div className="Landing-download">
          <p>Get started in the browser, or download the app.</p>
          <a
            className="btn-primary"
            href="https://github.com/Rydez/voximity-releases/releases/latest/download/Voximity-Installer.exe"
            rel="noreferrer"
            target="_blank"
          >
            <i className="icon icon-windows icon-dark icon-big" /> Windows
          </a>
        </div> */}
              <div className="Landing-demo">
                <h2 className="Landing-demo-main-title">Let's see how it works</h2>
                <div className="Landing-demo-row">
                  <div className="Landing-demo-video-col">
                    <video
                      poster={proximityPoster}
                      src={proximityVideo}
                      autoPlay={!isSafari}
                      controls={isSafari}
                      loop
                      muted
                      playsInline
                    />
                  </div>
                  <div className="Landing-demo-text-col">
                    <div className="Landing-demo-title">Proximity</div>
                    <div className="Landing-demo-description">
                      Say goodbye to chaotic voice and video calls! The closer you are, the louder
                      they sound. Move away, and the noise fades. Get everyone together in the same
                      space instead of isolating people to different channels or breakout rooms.
                    </div>
                  </div>
                </div>
                <div className="Landing-demo-row">
                  <div className="Landing-demo-text-col">
                    <div className="Landing-demo-title">Camera and screen sharing</div>
                    <div className="Landing-demo-description">
                      For more fun, you can share your screen and use your camera! When someone is
                      sharing their screen, you can adjust the volume, position, and size. This
                      flexibility makes it easy for multiple people to share at the same time,
                      creating a more engaging experience.
                    </div>
                  </div>
                  <div className="Landing-demo-video-col">
                    <video
                      poster={streamPoster}
                      src={streamVideo}
                      autoPlay={!isSafari}
                      controls={isSafari}
                      loop
                      muted
                      playsInline
                    />
                  </div>
                </div>
                <div className="Landing-demo-row">
                  <div className="Landing-demo-video-col">
                    <video
                      poster={whisperingPoster}
                      src={whisperingVideo}
                      autoPlay={!isSafari}
                      controls={isSafari}
                      loop
                      muted
                      playsInline
                    />
                  </div>
                  <div className="Landing-demo-text-col">
                    <div className="Landing-demo-title">Whispering</div>
                    <div className="Landing-demo-description">
                      Need a quick side chat? Just click someone to whisper them! While whispering,
                      you'll be quieter to others, and they'll be quieter to you. Whispering lets
                      you have side conversations that don't disrupt everyone else.
                    </div>
                  </div>
                </div>
                <div className="Landing-demo-row">
                  <div className="Landing-demo-text-col">
                    <div className="Landing-demo-title">Groups</div>
                    <div className="Landing-demo-description">
                      Effortlessly create or join groups to focus on specific topics. In a group,
                      you can whisper multiple people or choose to go 'isolated' to block out noise.
                      Groups only exist while in use and disappear once the last person leaves.
                    </div>
                  </div>
                  <div className="Landing-demo-video-col">
                    <video
                      poster={groupsPoster}
                      src={groupsVideo}
                      autoPlay={!isSafari}
                      controls={isSafari}
                      loop
                      muted
                      playsInline
                    />
                  </div>
                </div>
                <div className="Landing-demo-row">
                  <div className="Landing-demo-video-col">
                    <video
                      poster={textPoster}
                      src={textVideo}
                      autoPlay={!isSafari}
                      controls={isSafari}
                      loop
                      muted
                      playsInline
                    />
                  </div>
                  <div className="Landing-demo-text-col">
                    <div className="Landing-demo-title">Text and images</div>
                    <div className="Landing-demo-description">
                      Want to share a link, image, or witty remark? Send a message to share with
                      everyone in the room!
                    </div>
                  </div>
                </div>
              </div>
              <div className="Landing-beta-footer">
                <div className="Landing-beta-title">
                  <i className="icon icon-sparkle icon-big" />
                  Try it out yourself!
                </div>
                <p>
                  <span className="Landing-beta-bold">
                    Enjoy socializing remotely with Voximity
                  </span>
                  , the simplest way for everyone to feel together without being overwhelmed.
                </p>
                <button className="btn-primary" onClick={() => setForm('signup')}>
                  <i className="icon icon-sparkle icon-dark icon-big" /> SIGNUP
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
