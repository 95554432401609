export const longtouch = (self, onLongtouch) => {
  const duration = 500;
  return (event) => {
    event.preventDefault();

    let timer = setTimeout(() => onLongtouch(event), duration);

    const cancel = () => {
      self.off('touchend', cancel);
      self.off('touchmove', cancel);
      clearTimeout(timer);
    };

    self.once('touchend', cancel);
    self.once('touchmove', cancel);
  };
};

export const shorttouch = (self, onShorttouch) => {
  const duration = 100;
  return (event) => {
    event.preventDefault();
    const start = new Date().getTime();
    self.once('touchend', () => {
      const end = new Date().getTime();
      if (end - start < duration) {
        onShorttouch(event);
      }
    });
  };
};

export const shorttouchDom = (onShorttouch) => {
  const duration = 100;
  return (event) => {
    event.preventDefault();
    const start = new Date().getTime();
    event.target.addEventListener(
      'touchend',
      () => {
        const end = new Date().getTime();
        if (end - start < duration) {
          onShorttouch(event);
        }
      },
      { once: true }
    );
  };
};

export default longtouch;
