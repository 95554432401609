import { useState, useEffect } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import Modal from '../../../../components/modal/Modal';
import './ScreenPicker.css';

const { ipcRenderer } = typeof window.require === 'function' && window.require('electron');

function ScreenPicker({ setIsOpen }) {
  const self = useDyteSelector((m) => m.self);

  const [error, setError] = useState('');
  const [sources, setSources] = useState([]);
  const [sourceId, setSourceId] = useState(null);

  useEffect(() => {
    (async () => {
      const sources = await ipcRenderer.invoke('screen-sources');
      setSources(sources);
    })();
  }, []);

  const onStart = async () => {
    setError('');
    try {
      navigator.mediaDevices.getDisplayMedia = async () => {
        return navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: sourceId,
              minWidth: 1280,
              maxWidth: 1280,
              minHeight: 720,
              maxHeight: 720,
            },
          },
        });
      };

      await self.enableScreenShare();
      setIsOpen(false);
    } catch (err) {
      console.error(err);
      setError("Couldn't start screen share");
    }
  };

  const onClose = () => {
    setError('');
    setIsOpen(false);
  };

  return (
    <Modal
      name="screen-share-picker"
      title="Share Screen"
      close={onClose}
      footer={
        <button className="btn-primary" onClick={onStart}>
          Start
        </button>
      }
    >
      {!sources.length && <div className="ScreenPicker-loading">Loading...</div>}
      {!!sources.length && (
        <>
          <div className="ScreenPicker-container">
            {sources.map((source) => (
              <div
                key={source.id}
                onClick={() => setSourceId(source.id)}
                className={`ScreenPicker-source ${
                  sourceId === source.id ? 'ScreenPicker-selected' : ''
                }`}
              >
                <div className="ScreenPicker-source-title">{source.name}</div>
                <img src={source.thumbnail.toDataURL()} alt="screen thumbnail" />
              </div>
            ))}
          </div>
          {!!error && <div className="error">{error}</div>}
        </>
      )}
    </Modal>
  );
}

export default ScreenPicker;
