import { useRef, useEffect, useState } from 'react';
import useCache from '../../../lib/cache/context';
import { enableOtherScreen, disableOtherScreen } from '../../../lib/canvas/screens';
import * as vol from '../../../lib/volume';

function Participant({
  participant,
  soundEnabled,
  overallVolume,
  whisperVolume,
  proximityEnabled,
  screenVolumes,
  groupVolumes,
  distances,
  pins,
}) {
  const userId = participant.customParticipantId;

  const { state } = useCache();
  const [gainNode, setGainNode] = useState(null);
  const videoRef = useRef(null);

  useEffect(() => {
    if (pins[userId] || !participant.screenShareEnabled || !participant.screenShareTracks.video) {
      disableOtherScreen(userId);
      videoRef.current.srcObject = null;
      return;
    }

    const stream = new MediaStream();
    stream.addTrack(participant.screenShareTracks.video);
    if (participant.screenShareTracks.audio) {
      stream.addTrack(participant.screenShareTracks.audio);
      const audioContext = new window.AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const gain = audioContext.createGain();
      setGainNode(gain);

      source.connect(gain).connect(audioContext.destination);
    }

    videoRef.current.srcObject = stream;

    const enable = () => {
      enableOtherScreen(userId, videoRef, videoRef.current.videoHeight);
    };

    videoRef.current.addEventListener('loadedmetadata', enable, { once: true });
    videoRef.current.addEventListener('resize', enable);
  }, [
    pins,
    userId,
    participant.screenShareEnabled,
    participant.screenShareTracks.video,
    participant.screenShareTracks.audio,
  ]);

  useEffect(() => {
    const volume = vol.get(
      {
        self: state.self.data,
        users: state.users.data,
        groups: state.groups.data,
      },
      participant.customParticipantId,
      vol.proximity(distances, proximityEnabled),
      vol.screen(screenVolumes),
      vol.group(groupVolumes),
      vol.whisper(whisperVolume),
      vol.isolate(),
      vol.overall(overallVolume),
      vol.enabled(soundEnabled)
    );

    if (gainNode) {
      // Need to use a gain node to adjust volume because
      // Safari doesn't allow changing volume on mobile >_>
      gainNode.gain.value = volume;
    }
  }, [
    gainNode,
    state.self.data,
    state.users.data,
    state.groups.data,
    participant,
    soundEnabled,
    overallVolume,
    whisperVolume,
    proximityEnabled,
    distances,
    screenVolumes,
    groupVolumes,
  ]);

  return (
    <div>
      {/* This muted element must exist to work around a chrome bug. The source is set above. */}
      <video style={{ display: 'none' }} ref={videoRef} autoPlay playsInline muted />
    </div>
  );
}

export default Participant;
