import { useEffect, useState, useCallback } from 'react';
import actions from '../../../lib/cache/actions';
import useCache from '../../../lib/cache/context';
import { getGroup } from '../../../lib/cache/helpers';
import * as socket from '../../../lib/socket/socket';

function Group({ setNewGroupOpen }) {
  const { state, dispatch } = useCache();

  const selfId = state.self.data.id;
  const selfUser = state.users.data.find((u) => u.id === selfId);
  const selfGroup = getGroup(selfId, state.users.data, state.groups.data);

  const [whisperBindPressed, setWhisperBindPressed] = useState(false);

  const onWhisperGroupStart = useCallback(() => {
    socket.send({
      type: 'whisperGroup:start',
      data: { whisperGroupId: selfGroup.id },
    });

    actions.users.update(dispatch, { id: selfId, whisperGroupId: selfGroup.id });
  }, [dispatch, selfGroup, selfId]);

  const onWhisperGroupEnd = useCallback(() => {
    socket.send({
      type: 'whisperGroup:end',
      data: { whisperGroupId: null },
    });

    actions.users.update(dispatch, { id: selfId, whisperGroupId: null });
  }, [dispatch, selfId]);

  useEffect(() => {
    if (!selfUser || !selfGroup) {
      return;
    }

    if (!selfUser.whisperGroupId && state.keyBindings.active.includes('whisperGroup')) {
      setWhisperBindPressed(true);
      onWhisperGroupStart();
    }

    if (whisperBindPressed && !state.keyBindings.active.includes('whisperGroup')) {
      setWhisperBindPressed(false);
      onWhisperGroupEnd();
    }
  }, [
    selfUser,
    selfGroup,
    onWhisperGroupStart,
    onWhisperGroupEnd,
    state.keyBindings.active,
    whisperBindPressed,
  ]);

  const onLeaveGroup = async () => {
    await actions.users.leaveGroup(dispatch, selfId);
  };

  const isWhisperingGroup = () => {
    return state.users.data.some((u) => u.id === selfId && u.whisperGroupId);
  };

  const onToggleIsolateGroup = async () => {
    if (selfGroup.isIsolated) {
      await actions.groups.unisolate(dispatch, state.room.data.id, selfGroup.id);
    } else {
      await actions.groups.isolate(dispatch, state.room.data.id, selfGroup.id);
    }
  };

  return (
    <>
      {!selfGroup && (
        <button className="btn" onClick={() => setNewGroupOpen(true)}>
          <i className="icon icon-add" /> New group
        </button>
      )}
      {!!selfGroup && (
        <>
          <button className="btn" onMouseDown={onWhisperGroupStart} onMouseUp={onWhisperGroupEnd}>
            {isWhisperingGroup() ? (
              <>
                <i className="icon icon-speak" /> Whispering group
              </>
            ) : (
              <>
                <i className="icon icon-speak" /> Whisper group
              </>
            )}
          </button>
          <button className="btn" onClick={onToggleIsolateGroup}>
            {selfGroup.isIsolated ? (
              <>
                <i className="icon icon-isolate" /> Unisolate
              </>
            ) : (
              <>
                <i className="icon icon-isolate" /> Isolate
              </>
            )}
          </button>
          <button className="btn" onClick={onLeaveGroup}>
            <i className="icon icon-leave-group" /> Leave
          </button>
        </>
      )}
    </>
  );
}

export default Group;
