import { get } from '../client';

export const initialState = {
  data: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_ROOMS_USERS':
      return {
        ...state,
        data: payload.roomsUsers,
      };
    case 'ADD_ROOMS_USERS':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => !payload.roomsUsers.find((u) => u.id === d.id)),
          ...payload.roomsUsers,
        ],
      };
    case 'UPDATE_ROOMS_USERS':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => d.id !== payload.roomsUser.id),
          {
            ...(state.data.find((d) => d.id === payload.roomsUser.id) || {}),
            ...payload.roomsUser,
          },
        ],
      };
    case 'REMOVE_ROOMS_USERS':
      return {
        ...state,
        data: [...state.data.filter((d) => d.id !== payload.id)],
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch) {
    const roomsUsers = await get(`/users`);
    dispatch({
      type: 'FETCH_ROOMS_USERS',
      payload: { roomsUsers },
    });
  },
  remove(dispatch, id) {
    dispatch({
      type: 'REMOVE_ROOMS_USERS',
      payload: { id },
    });
  },
  update(dispatch, roomsUser) {
    dispatch({
      type: 'UPDATE_ROOMS_USERS',
      payload: { roomsUser },
    });
  },
  add(dispatch, roomsUsers) {
    dispatch({
      type: 'ADD_ROOMS_USERS',
      payload: { roomsUsers },
    });
  },
};
