import { get, post } from '../client';

export const initialState = {
  data: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_MESSAGES':
      return {
        ...state,
        data: payload.messages,
      };
    case 'UPDATE_MESSAGES':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => d.id !== payload.message.id),
          {
            ...(state.data.find((d) => d.id === payload.message.id) || {}),
            ...payload.message,
          },
        ],
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch, roomId) {
    const messages = await get(`/rooms/${roomId}/messages`);
    dispatch({
      type: 'FETCH_MESSAGES',
      payload: { messages },
    });
  },
  async create(dispatch, roomId, formData) {
    const message = await post(`/rooms/${roomId}/messages`, formData);
    dispatch({
      type: 'UPDATE_MESSAGES',
      payload: { message },
    });
  },
  update(dispatch, message) {
    dispatch({
      type: 'UPDATE_MESSAGES',
      payload: { message },
    });
  },
};
