import * as PIXI from 'pixi.js';
import { getGroup } from '../cache/helpers';
import { pixiState } from './state';
import { drawGroupName } from './groups';
import { whisperTo } from './whispers';
import { longtouch, shorttouch } from '../touch';
import { drawUser } from './users';

// Because this is an async function called from a useEffect, it could run twice.
// Use a lock to prevent a user from being created more than once.
const lock = {};

export const updateParticipants = async (
  { selfId, users, groups },
  dispatch,
  participantIds,
  setPopup
) => {
  if (!selfId) {
    return;
  }

  // Check for others that have left.
  for (const otherId of Object.keys(pixiState.others)) {
    if (participantIds.includes(otherId)) {
      continue;
    }

    const other = pixiState.others[otherId];
    other.container.removeAllListeners('mousedown');
    other.container.removeAllListeners('rightclick');
    other.container.removeAllListeners('touchstart');
    other.container.destroy({ children: true });

    if (other.avatar.startsWith('http')) {
      await PIXI.Assets.unload(other.avatar);
    }

    other.destroyed = true;
    delete pixiState.others[otherId];
  }

  // Check for users that haven't been added yet.
  for (const user of users) {
    if (user.id === selfId) {
      continue;
    }

    // Wait for participant to exist because it's
    // the true indicator of whether or not a user
    // is connected to the webRTC session.
    if (!participantIds.includes(user.id)) {
      continue;
    }

    const alreadyExists = pixiState.others[user.id];
    if (alreadyExists) {
      continue;
    }

    if (lock[user.id]) {
      continue;
    }

    lock[user.id] = true;

    const other = await drawUser(user);
    other.container.zIndex = 1;
    other.container.cursor = 'pointer';

    const group = getGroup(user.id, users, groups);
    if (group) {
      drawGroupName(other, group);
    }

    other.container.x = user.x;
    other.container.y = user.y;

    const startWhisper = whisperTo(selfId, user.id, dispatch);
    const openPopup = (event) => {
      setPopup({
        x: event.global.x,
        y: event.global.y,
        userId: user.id,
      });
    };

    // Desktop events.
    other.container.on('mousedown', startWhisper);
    other.container.on('rightclick', openPopup);

    // Mobile events.
    other.container.on('touchstart', longtouch(other.container, startWhisper));
    other.container.on('touchstart', shorttouch(other.container, openPopup));

    pixiState.others[user.id] = other;
    pixiState.room.addChild(other.container);

    delete lock[user.id];
  }
};
