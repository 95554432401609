import { useDyteSelector } from '@dytesdk/react-web-core';
import useCache from '../../../lib/cache/context';
import { getGroup } from '../../../lib/cache/helpers';
import './Status.css';

function Status({ isElectron }) {
  const participants = useDyteSelector((m) => m.participants.joined);
  const { state } = useCache();

  const getStatus = () => {
    const selfId = state.self.data.id;
    if (!selfId) {
      return '';
    }

    const self = state.users.data.find((u) => u.id === selfId);
    if (!self) {
      return '';
    }

    const selfGroup = getGroup(selfId, state.users.data, state.groups.data);

    const ps = Array.from(participants.values());

    // Local user whipering other.
    if (self.whisperUserId) {
      const p = ps.find((p) => p.customParticipantId === self.whisperUserId);
      if (p) {
        return `Whispering ${p.name}...`;
      }
    }

    if (selfGroup && self.whisperGroupId) {
      return `Whispering group...`;
    }

    const whisperer = state.users.data.find((u) => u.whisperUserId === selfId);
    if (whisperer) {
      const p = ps.find((p) => p.customParticipantId === whisperer.id);
      if (p) {
        return `${p.name} whispering you...`;
      }
    }

    const groupWhisperer =
      selfGroup && state.users.data.find((u) => u.whisperGroupId === selfGroup.id);
    if (groupWhisperer) {
      const p = ps.find((p) => p.customParticipantId === groupWhisperer.id);
      if (p) {
        return `${p.name} whispering your group...`;
      }
    }

    return '';
  };

  return <div className={`Status ${isElectron ? 'Status-electron' : ''}`}>{getStatus()}</div>;
}

export default Status;
