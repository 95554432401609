import { useEffect } from 'react';
import './Modal.css';

function Modal({ name, title, description, close, noClose, children, footer }) {
  useEffect(() => {
    const stopPropogation = (event) => {
      event.stopPropagation();
    };
    const container = document.getElementById('modal-container');
    if (container) {
      container.addEventListener('pointerenter', stopPropogation);
    }

    return () => container.removeEventListener('pointerenter', stopPropogation);
  }, []);

  return (
    <div id="modal-container" className={`Modal`} onClick={close}>
      <div id={`${name}-modal`} className={`Modal-content`} onClick={(e) => e.stopPropagation()}>
        <div className="Modal-header">
          <span>{title}</span>
          {!noClose && (
            <button className="Modal-close-button btn" type="button" onClick={close}>
              Close
            </button>
          )}
        </div>
        {!!description && <div className="Modal-description">{description}</div>}
        <div className="Modal-body">{children}</div>
        {!!footer && <div className="Modal-footer">{footer}</div>}
      </div>
    </div>
  );
}

export default Modal;
