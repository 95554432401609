import actions from '../cache/actions';

export const handlers = {
  'roomsUsers:update': (dispatch, { user }) => {
    actions.roomsUsers.update(dispatch, user);
  },
  'roomsUsers:remove': (dispatch, { user }) => {
    actions.roomsUsers.remove(dispatch, user.id);
  },
  'roomsUsers:add': (dispatch, { users }) => {
    actions.roomsUsers.add(dispatch, users);
  },
};
