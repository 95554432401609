import { get, post } from '../client';

export const initialState = {
  data: [],
};

export const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'FETCH_USERS':
      return {
        ...state,
        data: payload.users,
      };
    case 'UPDATE_USERS':
      return {
        ...state,
        data: [
          ...state.data.filter((d) => d.id !== payload.user.id),
          {
            ...(state.data.find((d) => d.id === payload.user.id) || {}),
            ...payload.user,
          },
        ],
      };
    default:
      return state;
  }
};

export const actions = {
  async fetch(dispatch, roomId) {
    const users = await get(`/rooms/${roomId}/users`);
    dispatch({
      type: 'FETCH_USERS',
      payload: { users },
    });
  },
  async joinGroup(dispatch, userId, groupId) {
    const user = await post(`/users/${userId}`, { groupId });
    dispatch({
      type: 'UPDATE_USERS',
      payload: { user },
    });
  },
  async leaveGroup(dispatch, userId) {
    const user = await post(`/users/${userId}`, { groupId: null });
    dispatch({
      type: 'UPDATE_USERS',
      payload: { user },
    });
  },
  update(dispatch, user) {
    dispatch({
      type: 'UPDATE_USERS',
      payload: { user },
    });
  },
};
