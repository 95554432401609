import { useEffect, useState } from 'react';
import './Greeting.css';
import { ReactComponent as Image } from './greeting.svg';

const configs = [
  {
    text0: false,
    text1: false,
    text2: false,
  },
  {
    text0: true,
    text1: false,
    text2: false,
  },
  {
    text0: true,
    text1: true,
    text2: false,
  },
  {
    text0: true,
    text1: true,
    text2: true,
  },
  {
    text0: false,
    text1: true,
    text2: true,
  },
  {
    text0: false,
    text1: false,
    text2: true,
  },
];

function Greeting() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    for (const [id, visible] of Object.entries(configs[index])) {
      const el = document.getElementById(id);
      if (el) {
        el.style.visibility = visible ? 'visible' : 'hidden';
      }
    }

    const timer = setTimeout(() => {
      setIndex((i) => {
        if (index === configs.length - 1) {
          return 0;
        }

        return i + 1;
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div className="Greeting">
      <Image />
    </div>
  );
}

export default Greeting;
