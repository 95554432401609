import { useState, useEffect, useRef } from 'react';
import { useDyteSelector } from '@dytesdk/react-web-core';
import actions from '../../lib/cache/actions';
import useCache from '../../lib/cache/context';
import { getGroup } from '../../lib/cache/helpers';
import './Popup.css';

function Popup({
  popup,
  setPopup,
  userVolumes,
  setUserVolumes,
  screenVolumes,
  setScreenVolumes,
  setGroupVolumes,
  groupVolumes,
  setPins,
  pins,
}) {
  const { state, dispatch } = useCache();
  const popupRef = useRef(null);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  useEffect(() => {
    const onClick = (event) => {
      if (document.getElementById('popup')) {
        if (!document.getElementById('popup').contains(event.target)) {
          setPopup(null);
        }
      }
    };

    window.addEventListener('click', onClick);
    return () => window.removeEventListener('click', onClick);
  }, [setPopup]);

  useEffect(() => {
    if (popupRef.current) {
      const xMax = window.innerWidth - popupRef.current.offsetWidth;
      const yMax = window.innerHeight - popupRef.current.offsetHeight;
      setX(Math.min(popup.x, xMax));
      setY(Math.min(popup.y, yMax));
    }
  }, [popupRef, popup.x, popup.y]);

  const left = x + 'px';
  const top = y + 'px';
  let visibility = 'visible';

  // Use (0, 0) because it's unlikely that anyone will click there,
  // and this will prevent overflow.
  if (x === 0 || y === 0) {
    visibility = 'hidden';
  }

  const participants = useDyteSelector((m) => m.participants.joined);
  const participant = Array.from(participants.values()).find(
    (p) => p.customParticipantId === popup.userId
  );

  // If the user leaves the room, unset the popup.
  if (!participant) {
    setPopup(null);
    return null;
  }

  const userId = participant.customParticipantId;
  const group = getGroup(userId, state.users.data, state.groups.data);

  const selfGroup = getGroup(state.self.data.id, state.users.data, state.groups.data);
  const isSameGroup = selfGroup && group && selfGroup.id === group.id;

  const onUserVolumeChange = (event) => {
    setUserVolumes((v) => ({
      ...v,
      [popup.userId]: event.target.value,
    }));
  };

  const onScreenVolumeChange = (event) => {
    setScreenVolumes((v) => ({
      ...v,
      [popup.userId]: event.target.value,
    }));
  };

  const onGroupVolumeChange = (groupId) => {
    return (event) => {
      setGroupVolumes((v) => ({
        ...v,
        [groupId]: event.target.value,
      }));
    };
  };

  const onJoinGroup = (groupId) => {
    return async () => {
      if (selfGroup) {
        await actions.users.leaveGroup(dispatch, state.self.data.id);
      }

      await actions.users.joinGroup(dispatch, state.self.data.id, groupId);
      setPopup(null);
    };
  };

  const onPin = () => {
    setPins((p) => ({
      ...p,
      [userId]: !p[userId],
    }));
  };

  const show = (name) => {
    if (!popup.settings) {
      return true;
    }

    return popup.settings.includes(name);
  };

  return (
    <div ref={popupRef} id="popup" className="Popup" style={{ visibility, left, top }}>
      {show('user-volume') && (
        <div className="Popup-setting">
          <div>{participant.name}'s volume</div>
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={userVolumes[popup.userId] || 0.5}
            onChange={onUserVolumeChange}
          />
        </div>
      )}
      {!!participant.screenShareEnabled && (
        <>
          {show('screen-pin') && (
            <div className="Popup-setting">
              <div>
                <label>
                  <input type="checkbox" checked={pins[userId] || false} onChange={onPin} />
                  {pins[userId] ? 'Unpin stream' : 'Pin stream'}
                </label>
              </div>
            </div>
          )}
          {show('screen-volume') && (
            <div className="Popup-setting">
              <div>Stream volume</div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={screenVolumes[popup.userId] || 0}
                onChange={onScreenVolumeChange}
              />
            </div>
          )}
        </>
      )}
      {!!group && (
        <>
          {show('group-volume') && (
            <div className="Popup-setting">
              <div>{group.name} group volume</div>
              <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={groupVolumes[group.id] || 1}
                onChange={onGroupVolumeChange(group.id)}
              />
            </div>
          )}
          {!isSameGroup && (
            <>
              {show('group-join') && (
                <div className="Popup-setting">
                  <button className="Popup-button btn-secondary" onClick={onJoinGroup(group.id)}>
                    Join group
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Popup;
