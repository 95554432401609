import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/modal/Modal';
import { post } from '../../../../lib/client';
import './Feedback.css';

function Feedback({ setIsOpen }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ email, message }) => {
    setLoading(true);
    setError('');
    try {
      await post('/feedback', { email, message });
      setSent(true);
      reset();
    } catch (err) {
      console.error(err);
      setError('Could not send message');
    }

    setLoading(false);
  };

  return (
    <Modal name="feedback" title="Feedback" close={() => setIsOpen(false)}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Feedback-field">
          <label className="Feedback-label">Your email</label>
          <input
            autoFocus
            type="email"
            disabled={loading}
            {...register('email', { required: true })}
          />
          {errors.email && <div className="error">Email required</div>}
        </div>
        <div className="Feedback-field">
          <label className="Feedback-label">Message</label>
          <textarea rows="5" disabled={loading} {...register('message', { required: true })} />
          {errors.message && <div className="error">Message required</div>}
        </div>
        {!!error && <div className="error">{error}</div>}
        {sent && <div className="success">Sent!</div>}
        <input className="btn" type="submit" disabled={loading} value="Send" />
      </form>
    </Modal>
  );
}

export default Feedback;
